<template>
  <LocPopper
    v-slot="{ isOpen }"
    ref="locPopper"
    v-click-outside="{
      handler: handleClickOutside,
      include: includedOutsideElements,
    }"
    data-test="loc-dropdown"
    :config="{
      placement,
      modifiers: [
        {
          name: 'offset',
          options: { offset: combinedOffset },
          enabled: true,
        },
      ],
    }"
    :trigger-element="triggerElement"
  >
    <div>
      <div
        :data-trigger="triggerElement ? undefined : true"
        class="tw-flex tw-items-center"
        :class="triggerClasses"
        @click.stop="handleDropdownClick(isOpen)"
        @mouseenter="hoverOpen"
        @mouseleave="hoverClose"
      >
        <!-- @slot Reference content to display tooltip for -->
        <slot :close="closeDropdown" :open="isOpen" />
      </div>
      <transition :name="transitionClass">
        <div
          v-show="isOpen && !disabled"
          id="loc-dropdown-content"
          ref="dataPopper"
          data-popper
          :class="contentClasses"
          :style="{
            width: contentMatchesTriggerWidth ? `${triggerWidth}px` : 'auto',
            maxWidth: contentMatchesTriggerWidth ? `${triggerWidth}px` : 'auto',
            maxHeight: maxAllowedHeight ? `${maxAllowedHeight}px` : 'auto',
          }"
          @mouseleave="hoverClose"
        >
          <slot v-if="!lazyLoad || isOpen" name="content" :close="closeDropdown" :is-open="isOpen" />
        </div>
      </transition>
    </div>
  </LocPopper>
</template>

<script lang="ts">
import { EmitEvents } from '@/modules/@core/models/emit-events';
import { defineComponent, PropOptions, PropType } from 'vue';
import LocPopper from '@/modules/@core/components/LocPopper/LocPopper.vue';
import { isDescendantNode } from '@/modules/@core/functions/utils/is-descendant-node';
import { ClickOutside } from '@/modules/vue/const/click-outside';
import '@/css/transitions.css';
import { isListeningToEmit } from '@/modules/@core/functions/is-listening-to-emit';
import { Options } from '@popperjs/core';
import hotkeys from 'hotkeys-js';

export interface IData {
  timer: NodeJS.Timeout | null;
  triggerWidth: number;
  triggerHeight: number;
  resizeObserver: ResizeObserver | null;
}

export default defineComponent({
  name: 'LocDropdown',

  components: {
    LocPopper,
  },

  directives: {
    ClickOutside,
  },

  props: {
    /**
     * Disables showing tooltip on hover
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    /**
     * Classes passed to data-trigger element
     */
    triggerClasses: {
      type: String,
      default: 'tw-cursor-default',
    },
    /**
     * Classes passed to data-content element
     */
    contentClasses: {
      type: String,
      default:
        'tw-z-50 tw-rounded tw-bg-white tw-shadow-subtle tw-font-sans tw-cursor-default tw-overflow-x-hidden tw-overflow-y-auto',
    },
    maxAllowedHeight: {
      type: Number,
      default: 500,
    },
    /**
     * X Offset of the dropdown from the reference element in pixels
     */
    distance: {
      type: Number,
      default: 0,
    },
    /**
     * Y Offset of the dropdown from the reference element in pixels
     */
    skidding: {
      type: Number,
      default: 0,
    },
    /**
     * Defines dropdown position relative to content.
     * All available options are explained here: https://popper.js.org/popper-documentation.html#Popper.placements
     *
     * `[auto-start, auto, auto-end, top-start, top, top-end, right-start,
     *  right, right-end, bottom-end, bottom, bottom-start, left-end, left, left-start]`
     */
    placement: {
      type: String as PropType<Options['placement']>,
      default: 'bottom-start',
    },
    /**
     * Whether to remain open when clicking on content
     */
    remainOpenOnContentClick: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether to remain open when clicking on the trigger element
     */
    remainOpenOnTriggerClick: {
      type: Boolean,
      default: false,
    },
    /**
     * List of css selectors, which on click won't close the modal
     */
    ignoreClose: {
      type: Array as PropType<string[]>,
      default: () => ['.v-menu__content'],
    },
    /**
     * When true, ResizeObserver assures the dropdown content has the same width as the trigger class
     */
    contentMatchesTriggerWidth: {
      type: Boolean,
      default: false,
    },
    /** When true, the content is not rendered until open */
    lazyLoad: {
      type: Boolean,
      default: false,
    },
    transitionClass: {
      type: String,
      default: 'loc-fade-faster',
    },
    /**
     * Allows to specify on which element should trigger the tooltip
     * Replaces element specified by [data-trigger] attribute
     * Useful if the element is outside of the RcPopper scope
     */
    triggerElement: {
      default: () => null,
      validator: (prop: HTMLElement | null) => prop === null || prop instanceof HTMLElement,
    } as PropOptions<HTMLElement | null>,
    /**
     * Delay between content open and tooltip display (ms)
     */
    delay: {
      type: Number,
      default: 0,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      timer: null,
      triggerWidth: 0,
      triggerHeight: 0,
      resizeObserver: null,
    };
  },

  computed: {
    combinedOffset(): number[] {
      return [this.distance, this.skidding];
    },
  },

  mounted() {
    this.handleEmit('open-trigger', this.openDropdown);
    this.handleEmit('close-trigger', this.closeDropdown);
    if (this.contentMatchesTriggerWidth) {
      this.initTriggerResizeObserver();
    }
    hotkeys.setScope('other');
    hotkeys('esc', 'other', () => {
      this.closeDropdown();
    });
    if (this.alwaysOpen) {
      this.openDropdown();
    }
  },

  beforeDestroy() {
    hotkeys.unbind('esc', 'other');
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.resizeObserver?.disconnect();
    this.resizeObserver = null;
  },

  methods: {
    initTriggerResizeObserver() {
      const trigger = this.$el.querySelector('[data-trigger]');
      if (trigger) {
        this.resizeObserver = Object.freeze(
          new ResizeObserver((entries) => {
            const element = entries[0].target as HTMLElement;
            if (this.triggerHeight === 0) {
              this.triggerHeight = element.offsetHeight;
            }
            if (this.triggerHeight !== element.offsetHeight) {
              this.triggerHeight = element.offsetHeight;
              const popper = this.$refs.locPopper as any;
              if (popper?.isOpen) {
                /** In case the trigger height's changes and is open, it's likely it needs repositioning */
                popper.updatePopper();
              }
            }
            this.triggerWidth = element.offsetWidth;
          }),
        );
        this.resizeObserver.observe(trigger);
      } else {
        // eslint-disable-next-line no-console
        console.warn('Resize observer trigger could not be initialized in LocDropdown');
      }
    },

    handleClickOutside(e: Event) {
      const relatedTarget = e.target as HTMLElement;
      if (!this.$refs.locPopper) {
        return;
      }

      // @ts-expect-error types
      if (!isDescendantNode(this.$refs.dataPopper as HTMLElement, relatedTarget) && this.$refs.locPopper.isOpen) {
        this.closeDropdown();
      }
    },

    handleDropdownClick(isOpen: boolean) {
      if (isOpen && !this.remainOpenOnTriggerClick) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    },

    openDropdown(): void {
      if (this.disabled) {
        return;
      }
      this.timer = setTimeout(() => {
        this.openTrigger();
        if (!this.remainOpenOnContentClick && this.$refs.dataPopper) {
          // @ts-expect-error types
          this.$refs.dataPopper.addEventListener('click', this.closeDropdown);
        }
        this.handleEmit('open');
      }, this.delay);
    },

    closeDropdown(): void {
      if (this.alwaysOpen) {
        return;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.closeTrigger();
      this.handleEmit('close');
      if (!this.remainOpenOnContentClick && this.$refs.dataPopper) {
        // @ts-expect-error types
        this.$refs.dataPopper.removeEventListener('click', this.closeDropdown);
      }
    },

    hoverClose(e: MouseEvent) {
      if (this.hover) {
        const relatedTarget = e.relatedTarget as HTMLElement;
        if (!isDescendantNode(this.$refs.dataPopper as HTMLElement, relatedTarget)) {
          this.closeDropdown();
        }
      }
    },

    hoverOpen() {
      if (this.hover) {
        this.openDropdown();
      }
    },

    openTrigger() {
      const popper = this.$refs.locPopper;
      if (popper) {
        return (popper as any).open();
      }
      return false;
    },

    closeTrigger() {
      const popper = this.$refs.locPopper;
      if (popper) {
        return (popper as any).close();
      }
      return false;
    },

    includedOutsideElements(): HTMLElement[] {
      const result: HTMLElement[] = [];
      this.ignoreClose.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((el) => {
          if (el !== null) {
            result.push(el as HTMLElement);
          }
        });
      });
      return result;
    },

    handleEmit(event: EmitEvents, payload?: unknown) {
      if (isListeningToEmit(event, this.$listeners as any)) {
        this.$emit(event, payload);
      }
    },
  },
});
</script>
