<template>
  <div class="tw-flex tw-w-10 tw-justify-end">
    <LocTooltip v-if="item.tooltip" placement="top" :class="{ 'tw-mr-1': item.docs }">
      <LocIcon name="info" :size="16" class="tw-fill-secondary hover:tw-fill-primary" :style="{ cursor: 'help' }" />

      <template #content>
        {{ item.tooltip }}
      </template>
    </LocTooltip>

    <a v-if="item.docs" :href="item.docs" target="_blank">
      <LocIcon name="open-in-new" :size="16" class="tw-fill-secondary hover:tw-fill-primary" />
    </a>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { FormControlComponent } from '@localazy/markdown-it-compiler';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export default defineComponent({
  name: 'DynamicFormControlHelp',

  components: {
    LocTooltip,
    LocIcon,
  },

  props: {
    item: {
      type: Object as PropType<FormControlComponent>,
      required: true,
    },
  },
});
</script>
