<template>
  <component
    :is="hasTooltip ? 'loc-tooltip' : 'div'"
    :placement="hasTooltip && tooltip.placement ? tooltip.placement : undefined"
    :secondary="hasTooltip && tooltip.secondary ? true : undefined"
    class="tw-flex tw-w-full tw-m-0 tw-p-0"
  >
    <RouterLink
      v-if="isLink"
      v-slot="{ navigate }"
      custom
      class="tw-flex tw-w-full tw-no-underline tw-text-secondary-darken-2"
      :class="[
        cellClasses,
        {
          'tw-cursor-pointer': hasClickEvent,
          'tw-items-start': topAligned,
          'tw-items-center': !topAligned,
        },
      ]"
      :to="getLink(row)"
    >
      <!-- In Vue 3 we can no longer use click.native modifier. Instead, we have to define custom content of the router-link. -->
      <a
        v-if="link"
        class="tw-flex tw-w-full"
        :href="link(row)"
        @click="onClickOnLink($event, navigate)"
        @click.middle="onClickOnLink($event, navigate)"
      >
        <slot />
      </a>
    </RouterLink>
    <div
      v-else
      class="tw-flex tw-w-full tw-text-secondary-darken-2"
      :class="[
        cellClasses,
        {
          'tw-cursor-pointer': hasClickEvent,
          'tw-items-start': topAligned,
          'tw-items-center': !topAligned,
        },
      ]"
      @click="onClickOnLink($event)"
    >
      <slot />
    </div>

    <template v-if="hasTooltip" #content>
      {{ tooltip.content }}
    </template>
  </component>
</template>

<script lang="ts">
import { CellTooltip } from '@/modules/@core/models/cell-tooltip';
import { Header } from '@/modules/@core/models/header';
import { HeaderOnClickArguments } from '@/modules/@core/models/header-on-click-arguments';
import { Row } from '@/modules/@core/models/row';
import { defineComponent, PropOptions, PropType } from 'vue';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';

export default defineComponent({
  name: 'LocDataTableCellLink',

  components: {
    LocTooltip,
  },

  props: {
    row: {
      type: Object as PropType<Row>,
      required: true,
    },
    isLink: {
      type: Boolean,
      required: true,
    },
    link: {
      required: true,
      validator: (link: undefined | ((..._args: any[]) => any)) => link === undefined || typeof link === 'function',
    } as PropOptions<Header['link']>,
    cellClasses: {
      type: String,
      required: true,
    },
    hasClickEvent: {
      type: Boolean,
      required: true,
    },
    topAligned: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object as PropType<CellTooltip>,
      required: true,
    },
  },

  computed: {
    hasTooltip(): boolean {
      return !!this.tooltip.content;
    },
  },

  methods: {
    onClickOnLink(e: MouseEvent, navigate?: (..._args: any[]) => any) {
      this.$emit('click', {
        mouseEvent: e,
        row: this.row,
      } as HeaderOnClickArguments<Row>);
      if (navigate) {
        navigate(e);
      }
    },

    getLink(row: Row): string {
      return this.link ? this.link(row) || '' : '';
    },
  },
});
</script>
