<template>
  <div class="tw-flex tw-flex-wrap tw-items-center" :class="item.cssClass">
    <div
      v-if="!item.isCheckbox && (item.label || item.tooltip || item.docs)"
      class="tw-flex tw-w-full tw-text-sm tw-text-grey tw-leading-none tw-mb-1"
    >
      <div class="tw-flex-grow">
        {{ item.label }}
      </div>

      <DynamicFormControlHelp v-if="item.tooltip || item.docs" :item="item" />
    </div>

    <div class="tw-flex tw-items-center tw-w-full">
      <div class="tw-flex-grow">
        <component :is="component" v-model="value" v-bind="item.props">
          <template v-if="item.isCheckbox">
            {{ item.label }}
          </template>
        </component>
      </div>

      <DynamicFormControlHelp v-if="item.isCheckbox && (item.tooltip || item.docs)" :item="item" />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, Component } from 'vue';
import { FormControlComponent, UpdateModelEvent } from '@localazy/markdown-it-compiler';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocInput from '@/modules/@core/components/LocInput/LocInput.vue';
import LocCheckbox from '@/modules/@core/components/LocCheckbox/LocCheckbox.vue';
import LocSelect from '@/modules/@core/components/LocSelect/LocSelect.vue';
import DynamicFormControlHelp from '@/modules/@core/components/LocMarkdown/markdown-it-compiler/DynamicFormControlHelp.vue';

export default defineComponent({
  name: 'DynamicFormControl',

  components: {
    LocTooltip,
    LocIcon,
    LocInput,
    LocCheckbox,
    LocSelect,
    DynamicFormControlHelp,
  },

  props: {
    item: {
      type: Object as PropType<FormControlComponent>,
      required: true,
    },
  },

  computed: {
    component(): Component {
      switch (this.item.component) {
        case 'select':
          return LocSelect;
        case 'checkbox':
          return LocCheckbox;
        case 'input':
        default:
          return LocInput;
      }
    },
    value: {
      get(): unknown {
        return this.item.value;
      },
      set(val: unknown) {
        const event: UpdateModelEvent = {
          controlId: this.item.id,
          formId: this.item.formControl.form.id,
          value: val,
        };

        this.$emit('update-model', event);
      },
    },
  },
});
</script>
