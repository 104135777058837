<template>
  <div
    class="tw-bg-white tw-flex tw-flex-col tw-justify-between tw-border-0 tw-border-t-2 tw-border-solid tw-border-primary-lighten-3"
  >
    <div v-if="expandedId !== ''" class="tw-border-0 tw-border-b-2 tw-border-solid tw-border-grey-lighten-4">
      <!-- @slot expanded drawer area -->
      <slot name="expanded" />
    </div>
    <div class="tw-flex">
      <LocMenuItem
        v-for="item in menuItems"
        :key="item.id"
        :item="item"
        is-expanded
        :is-active="item.id === activeItemId"
        :show-label="false"
        item-content-order="initial"
        is-mobile-menu
        :class="{
          'tw-border-l-2 tw-border-solid tw-border-grey-lighten-4': item.hasDivider,
        }"
        class="tw-flex-1 tw-justify-center tw-border-0 drawer-item"
        data-test="loc-drawer-menu-item"
        centered
        @click="onItemClick"
      >
        <template #icon>
          <slot :name="`${item.id}-icon`" :item="item" :is-active="item.id === activeItemId" />
        </template>

        <template #badge>
          <slot :name="`${item.id}-badge`" :item="item" :is-active="item.id === activeItemId" />
        </template>

        <template #label>
          <slot :name="`${item.id}-label`" :item="item" :is-active="item.id === activeItemId" />
        </template>
      </LocMenuItem>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { MenuItem } from '@/modules/@core/models/menu-item';
import LocMenuItem from '@/modules/@core/components/LocMenuItem/LocMenuItem.vue';

export default defineComponent({
  name: 'LocDrawerMenu',

  components: {
    LocMenuItem,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    activeItemId: {
      type: String,
      default: '',
    },
  },

  computed: {
    expandedId: {
      get(): string {
        return this.value;
      },
      set(val: string): void {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    onItemClick(item: MenuItem) {
      this.expandedId = item.id !== this.expandedId ? item.id : '';
      this.$emit('click', item.id);
    },

    hasChildren(item: MenuItem) {
      return !!item.children?.length;
    },
  },
});
</script>

<style lang="postcss">
.drawer-item {
  min-height: 48px;
}
</style>
