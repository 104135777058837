<template>
  <div class="tw-flex tw-justify-between tw-text-sm tw-h-full tw-items-center">
    <div v-if="isDefaultSlotSet" data-test="ldt-batch-default-slot">
      <slot />
    </div>
    <div
      v-if="!!selectedCount"
      data-test="ldt-batch-options-selection"
      class="tw-flex-1 tw-flex tx-flex-row md:tw-items-center tw-justify-between tw-gap-2"
    >
      <div class="tw-flex tw-gap-x-1 tw-sticky tw-left-0 tw-px-3 tw-py-1">
        <span data-test="ldt-batch-options-select-count" class="tw-font-medium">
          <slot name="selected-count-text" :selected-count="selectedCount">
            {{ selectedCountText }}
          </slot>
        </span>
        <span
          v-if="!isAllFilteredSelected && hasFilteredRows"
          class="hover:tw-underline tw-text-primary tw-cursor-pointer"
          data-test="ldt-batch-options-select-all"
          @click="selectAll"
        >
          <slot name="select-all-text" :non-selected-filtered-count="nonSelectedInFilteredRowsCount">
            {{ addItemsToSelectionText }}
          </slot>
        </span>
      </div>
      <div
        class="tw-flex tw-flex-col md:tw-flex-row tw-items-end md:tw-items-center tw-sticky tw-right-0 tw-px-1 xl:tw-px-2 tw-gap-3"
      >
        <!-- clear selection section -->
        <span
          class="tw-flex tw-gap-x-two tw-items-center tw-font-medium tw-transition-colors tw-duration-100 tx-p-1 tw-cursor-pointer tw-fill-secondary tw-text-secondary hover:tw-fill-primary hover:tw-text-primary"
          data-test="ldt-batch-options-clear-all"
          @click="clearAll"
        >
          <slot name="clear-select-btn">
            <LocIcon name="clear" :size="16" />
            <slot name="clear-select-text">
              {{ t('LocDataTableSelectionOptions.clear-selection') }}
            </slot>
          </slot>
        </span>
        <div class="tw-flex tw-gap-x-2">
          <slot name="clear-select-adjacent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Row } from '@/modules/@core/models/row';
import { defineComponent, PropType } from 'vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import { differenceBy, intersectionBy } from 'lodash-es';
import { formatNumber } from '@/modules/@core/functions/utils/format/format-number';
import { langService } from '@/modules/translations/const/lang-service';

export default defineComponent({
  name: 'LocDataTableSelectionOptions',

  components: {
    LocIcon,
  },

  props: {
    selected: {
      type: Array as PropType<Row[]>,
      required: true,
    },
    filteredRows: {
      type: Array as PropType<Row[]>,
      required: true,
    },
    rowUniqueAttribute: {
      type: String,
      required: true,
    },
  },

  computed: {
    selectedCount(): number {
      return this.selected.length;
    },
    filteredRowsCount(): number {
      return this.filteredRows.length;
    },
    hasFilteredRows(): boolean {
      return !!this.filteredRowsCount;
    },
    nonSelectedInFilteredRows(): Row[] {
      return differenceBy(this.filteredRows, this.selected, this.rowUniqueAttribute);
    },
    nonSelectedInFilteredRowsCount(): number {
      return this.nonSelectedInFilteredRows.length;
    },
    selectedInFilteredRows(): Row[] {
      return intersectionBy(this.selected, this.filteredRows, this.rowUniqueAttribute);
    },
    selectedInFilteredRowsCount(): number {
      return this.selectedInFilteredRows.length;
    },
    isSelectedInFilteredRows(): boolean {
      return this.hasFilteredRows && !!this.selectedInFilteredRowsCount;
    },
    isAllFilteredSelected(): boolean {
      return this.hasFilteredRows && !this.nonSelectedInFilteredRowsCount;
    },
    isOneSelected(): boolean {
      return this.selectedCount === 1;
    },
    isSomeSelected(): boolean {
      return this.selectedCount > 0;
    },
    isDefaultSlotSet(): boolean {
      return this.$slots.default !== undefined;
    },
    selectedCountText(): string {
      if (this.isOneSelected) {
        return `${formatNumber(this.selectedCount)} item selected.`;
      }

      return `${formatNumber(this.selectedCount)} items selected.`;
    },
    addItemsToSelectionText(): string {
      return `Select all ${formatNumber(this.filteredRowsCount)} items.`;
    },
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },

    selectAll() {
      this.$emit('select-all');
    },

    clearAll() {
      this.$emit('clear-all');
    },
  },
});
</script>
