<template>
  <!-- References
    https://tailwindcomponents.com/component/circular-progress-bar
    https://css-tricks.com/building-progress-ring-quickly/
  -->
  <div class="tw-inline-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full">
    <svg :style="{ height: `${width}px`, width: `${width}px` }">
      <circle
        :stroke-width="strokeWidth"
        stroke="currentColor"
        fill="transparent"
        :r="radius"
        :cx="width / 2"
        :cy="width / 2"
        :style="{ stroke: backgroundColor }"
      />
      <circle
        class="tw-transform tw--rotate-90 tw-origin-center"
        :stroke-width="strokeWidth"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="circumference - (value / 100) * circumference"
        stroke-linecap="round"
        fill="transparent"
        :r="radius"
        :cx="width / 2"
        :cy="width / 2"
        :style="{ stroke: progressColor }"
      />
    </svg>
    <div
      class="tw-absolute tw-flex tw-items-center tw-justify-center"
      :style="{ color: progressColor, fontSize: `${width * 0.35}px` }"
    >
      <slot name="middle">
        {{ value }}
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocCircularProgress',

  props: {
    /**
     * Progress has to be a value between 0 and 100
     */
    value: {
      type: Number,
      validator: (val: number) => val >= 0 && val <= 100,
      default: 0,
    },
    /**
     * Progress circle width in pixels
     */
    width: {
      type: Number,
      default: 60,
    },
    /**
     * Size of the ring
     */
    strokeWidth: {
      type: Number,
      default: 4,
    },
    /**
     * Color of the progress bar fill<br>
     * Pass standard colour name listed in variables.css
     */
    color: {
      type: String,
      default: 'primary',
    },
    /**
     * Color of the progress background<br>
     * Pass standard colour name listed in variables.css
     */
    bgColor: {
      type: String,
      default: 'grey-lighten-3',
    },
  },

  computed: {
    progressColor(): string {
      return `var(--${this.color})`;
    },
    backgroundColor(): string {
      return `var(--${this.bgColor})`;
    },
    radius(): number {
      return this.width / 2 - this.strokeWidth;
    },
    circumference(): number {
      return this.radius * 2 * Math.PI;
    },
  },
});
</script>
