<template>
  <div class="loc-badge tw-relative tw-inline-block">
    <!-- @slot Content to add badge to -->
    <slot />

    <div
      v-if="!hidden"
      data-test="loc-badge-circle"
      class="loc-badge-circle tw-absolute tw-leading-none tw-text-xs tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center"
      :class="[{ 'loc-badge-circle--dot': dot }, wrapperClasses]"
      :style="computedStyles"
    >
      <slot name="badge" :value="value">
        <span>{{ dot ? '&nbsp;' : value }}</span>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocBadge',

  props: {
    value: {
      type: [String, Number],
      default: '•',
    },
    color: {
      type: String,
      default: 'error',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    dot: {
      type: Boolean,
      default: false,
    },
    wrapperClasses: {
      type: String,
      default: '',
    },
  },

  computed: {
    computedStyles() {
      const backgroundColor = `var(--${this.color})`;
      if (this.dot) {
        return {
          backgroundColor,
          left: this.right ? 'auto' : '-2px',
          right: this.right ? '-2px' : 'auto',
          top: this.bottom ? 'auto' : '-2px',
          bottom: this.bottom ? '-2px' : 'auto',
        };
      }
      return {
        backgroundColor,
        left: this.right ? 'auto' : '-8px',
        right: this.right ? '-8px' : 'auto',
        top: this.bottom ? 'auto' : '-8px',
        bottom: this.bottom ? '-8px' : 'auto',
      };
    },
  },
});
</script>

<style lang="postcss" scoped>
.loc-badge-circle {
  padding: 2px 2px;
  min-height: 20px;
  min-width: 20px;
}
.loc-badge-circle--dot {
  padding: 0;
  min-width: auto;
  min-height: auto;
  width: 10px;
  height: 10px;
}
</style>
