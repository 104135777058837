<template>
  <LocSelect
    ref="locSelect"
    :content-classes="mergedAutocompleteContentClasses"
    :remain-open-on-content-click="remainOpenOnContentClick"
    :remain-open-on-trigger-click="!closeOnTriggerClick"
    :items="filteredSortedItems"
    :disabled="disabled"
    :ignore-close="ignoreClose"
    :hide-no-data="hideNoData"
    :max-allowed-height="maxAllowedHeight"
    content-matches-trigger-width
    :always-open="alwaysDisplaySuggestions"
    :loc-dropdown-item-props="{
      borderless: borderlessItems,
    }"
    :value="internalSelected[0] || ''"
    @open="onOpen"
    @close="onClose"
    @input="onItemSelected"
    @keyup-up="onSelectItemsKeyUp"
  >
    <template #selection>
      <div class="tw-flex tw-flex-col tw-w-full">
        <LocInput
          :id="id"
          ref="loc-input"
          :value="internalSearch"
          class="tw-w-full"
          :placeholder="placeholder"
          :type="type"
          :autocomplete="autocomplete"
          :required="required"
          :name="name"
          :readonly="readonly"
          :no-details="noDetails"
          :small="small"
          :large="large"
          :disabled="disabled"
          :error="error"
          :autofocus="autofocus"
          :label="label"
          :label-help="labelHelp"
          :input-classes="inputClasses"
          :counter="counter"
          :simple="simple"
          :no-border="noBorder"
          @input="onType"
          @keyup="onInputKeyUp"
          @escape="onEscapeInput"
          @blur="$emit('blur')"
        >
          <template v-if="!isOpen || keepInputBeforeVisibleWhenOpen" #input-before>
            <slot name="input-before" />
          </template>
          <template #input-value-after>
            <slot name="input-value-after" />
          </template>
          <template #icon-right>
            <slot name="icon-right">
              <div
                v-if="internalSearch && isOpen"
                class="tw-flex-shrink-0 tw-flex tw-rounded tw-p-px tw-cursor-pointer tw-fill-secondary tw-ml-1 tw--mr-two tw-transition-colors tw-duration-200 hover:tw-bg-primary-lighten-5 hover:tw-fill-primary"
                @click.stop="onClearInput"
              >
                <LocIcon :size="small ? 16 : 18" name="clear" />
              </div>
              <div v-else-if="singleSelection" class="tw-flex tw-items-center tw-flex-shrink-0 tw-ml-1">
                <LocIcon
                  name="chevron-right"
                  dir="right"
                  :class="isOpen ? 'tw-fill-primary' : 'tw-fill-grey'"
                  :size="small ? 10 : 12"
                />
              </div>
              <div v-else class="tw-flex tw-items-center tw-flex-shrink-0 tw-ml-1">
                <LocIcon name="search" :size="20" />
              </div>
            </slot>
          </template>
        </LocInput>
        <slot name="below-input" />
      </div>
    </template>

    <template #above-options>
      <slot name="above-options" />
    </template>

    <template #below-options>
      <slot name="below-options" />
    </template>

    <template #no-data>
      <slot name="no-data">
        <div class="tw-bg-grey-lighten-5 tw-text-grey tw-text-base tw-p-3 tw-border-t tw-border-grey-lighten-4">
          <slot name="no-data-text">
            {{ t('LocAutocomplete.no-data-available') }}
          </slot>
        </div>
      </slot>
    </template>

    <template #item="{ item }">
      <slot name="item" :item="item" />
    </template>

    <template #item-content="{ item, active, hovered, isDisabled }">
      <slot name="item-content" :item="item" :active="active" :hovered="hovered" :is-disabled="isDisabled" />
    </template>
  </LocSelect>
</template>

<script lang="ts">
import { isCompositeItem } from '@/modules/@core/functions/is-composite-item';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocInput from '@/modules/@core/components/LocInput/LocInput.vue';
import LocSelect from '@/modules/@core/components/LocSelect/LocSelect.vue';
import { defaultFuzzySearchOptions } from '@/modules/@core/const/default-fuzzy-search-options';
import { getFuse } from '@/modules/@core/functions/fuzzy-search';
import { arrayDifference } from '@/modules/@core/functions/utils/array-difference';
import { generateRandomId } from '@/modules/@core/functions/utils/generate-random-id';
import { FuzzySearchOptions } from '@/modules/@core/models/loc-autocomplete';
import { SelectItem } from '@/modules/@core/models/select-item';
import { langService } from '@/modules/translations/const/lang-service';
import { ClickOutside } from '@/modules/vue/const/click-outside';
import FuseType, { FuseResult } from 'fuse.js';
import { get } from 'lodash-es';
import { defineComponent, PropType } from 'vue';

export interface IData {
  isOpen: boolean;
  internalSelected: SelectItem[];
  internalSearch: string;
  fuse: FuseType<SelectItem> | null;
}

export default defineComponent({
  name: 'LocAutocomplete',

  components: {
    LocInput,
    LocSelect,
    LocIcon,
  },

  directives: {
    ClickOutside,
  },

  props: {
    value: {
      type: [Array, Object] as PropType<SelectItem[] | SelectItem>,
      default: (): SelectItem[] => [],
    },
    search: {
      type: String,
      default: '',
    },
    searchByPaths: {
      type: Array as PropType<string[]>,
      default: () => ['label', 'value'],
    },
    fuzzySearchOptions: {
      type: Object as PropType<Partial<FuzzySearchOptions>>,
      default: (): Partial<FuzzySearchOptions> => ({
        enabled: false,
        keys: defaultFuzzySearchOptions.keys || [],
      }),
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      required: true,
    },
    alwaysDisplaySuggestions: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    maxSuggestedItems: {
      type: Number,
      default: 100,
    },
    // LocInput props
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    simple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Input version without label & messages.
     * Reduces component height to the input box itself.
     */
    noDetails: {
      type: Boolean,
      default: false,
    },
    /**
     * Error message to be shown under the input box.
     */
    error: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelHelp: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    inputClasses: {
      type: String,
      default: '',
    },
    counter: {
      type: Number,
      default: 0,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the options dropdown should remain open on option click
     */
    remainOpenOnContentClick: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether to remain open when clicking on the trigger element
     */
    closeOnTriggerClick: {
      type: Boolean,
      default: false,
    },
    /**
     * Classes passed to data-content element
     */
    autocompleteContentClasses: {
      type: String,
      default: 'tw-max-w-sm',
    },
    maxAllowedHeight: {
      type: Number,
      default: 400,
    },
    hideNoData: {
      type: Boolean,
      default: false,
    },
    /**
     * List of css selectors, which on click won't close the modal
     */
    ignoreClose: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
    customSort: {
      type: Function as PropType<(_a: SelectItem, _b: SelectItem) => number>,
      default: (a: SelectItem, b: SelectItem): number => 1,
    },
    /** When options dropdown is open, the input-before slot is ignored by default. */
    keepInputBeforeVisibleWhenOpen: {
      type: Boolean,
      default: false,
    },
    borderlessItems: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      isOpen: false,
      internalSelected: [{ label: '', value: '' }],
      internalSearch: this.search,
      fuse: null,
    };
  },

  computed: {
    selectedLabel(): string {
      if (isCompositeItem(this.inputValue)) {
        return this.inputValue.label.toString();
      }
      if (this.inputValue) {
        return this.inputValue.toString();
      }
      return '';
    },
    mergedAutocompleteContentClasses(): string {
      return `tw-z-50 tw-rounded tw-bg-white tw-shadow-subtle tw-font-sans tw-cursor-default tw-overflow-auto ${this.autocompleteContentClasses} ${this.componentId}`;
    },
    inputValue: {
      get(): SelectItem[] | SelectItem {
        return this.value;
      },
      set(value: string | number): void {
        this.$emit('input', value);
      },
    },
    filteredItems(): SelectItem[] {
      if (!this.internalSearch) {
        return this.selectedExcludedItems;
      }

      if (this.singleSelection && this.internalSearch === this.selectedLabel) {
        return this.selectedExcludedItems;
      }

      if (!this.fuzzySearchOptions.enabled || this.fuse === null) {
        return this.selectedExcludedItems
          .filter((item) => this.isSearchByPathsMatched(item))
          .slice(0, this.maxSuggestedItems);
      }
      const MIN_SCORE = defaultFuzzySearchOptions.minScore;
      return this.fuse
        .search(this.internalSearch)
        .filter(
          (result: FuseResult<SelectItem>) =>
            (result.score || MIN_SCORE) <= MIN_SCORE &&
            (this.singleSelection || !this.internalSelected.some((item) => item.value === result.item.value)),
        )
        .map((result: FuseResult<SelectItem>) => result.item)
        .slice(0, this.maxSuggestedItems);
    },
    filteredSortedItems(): SelectItem[] {
      return [...this.filteredItems].sort(this.customSort as (a: SelectItem, b: SelectItem) => number);
    },
    selectedExcludedItems(): SelectItem[] {
      if (!this.singleSelection) {
        return arrayDifference(this.items, 'value', this.internalSelected, 'value');
      }
      // Single select should always contain all dropdown items
      return this.items;
    },
    componentId(): string {
      return generateRandomId();
    },
  },

  watch: {
    items: {
      handler(items: SelectItem[]) {
        this.fuse?.setCollection(items);
      },
    },

    value: {
      immediate: true,

      handler(newValue: SelectItem[] | SelectItem) {
        this.internalSelected = Array.isArray(newValue) ? newValue : [newValue];
        if (this.singleSelection) {
          this.setSearch(this.internalSelected[0].label);
        }
      },
    },

    search: {
      handler(newValue: string) {
        this.internalSearch = newValue;
      },
    },
  },

  created() {
    if (this.fuzzySearchOptions.enabled) {
      this.fuse = getFuse(
        {
          ...defaultFuzzySearchOptions,
          ...this.fuzzySearchOptions,
        },
        this.items,
      );
      this.fuse.setCollection(this.items);
    }
  },

  mounted() {
    this.openDropdownIfAutofocused();
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },

    onItemSelected(item: SelectItem) {
      this.$emit('item-selected', item);
      if (this.singleSelection) {
        this.internalSelected = [item];
      } else {
        this.internalSelected.push(item);
      }
      this.inputValue = this.singleSelection ? item : [...this.internalSelected];
      if (this.clearOnSelect) {
        this.setSearch('');
      } else if (this.singleSelection) {
        this.setSearch(item.label);
      }
    },

    onType(value: string) {
      this.setSearch(value);
      this.openTrigger(); // Make sure any time the input is typed, the dropdown is opened
    },

    setSearch(value: string) {
      this.scrollToTop();
      this.internalSearch = value;
      this.$emit('update:search', value);
    },

    onInputKeyUp(e: KeyboardEvent) {
      if (e.key === 'ArrowDown') {
        this.onEscapeInput();
        const popper = this.$refs.locSelect;
        if (popper) {
          (popper as any).focusElement('first');
        }
      }

      if (e.key === 'Enter' && this.error === '') {
        this.$emit('enter', this.internalSearch);
        this.setSearch('');
        if (!this.remainOpenOnContentClick) {
          this.closeTrigger();
        }
      }
      this.$emit('keyup', e);
    },

    onSelectItemsKeyUp(e: KeyboardEvent) {
      // @ts-expect-error types
      if (e.target && e.target.previousSibling === null) {
        this.focusInput();
      }
    },

    isSearchByPathsMatched(item: SelectItem) {
      return this.searchByPaths.some((path) => {
        const pathVal = get(item, path);
        return typeof pathVal === 'string' && pathVal.toLowerCase().includes(this.internalSearch.toLowerCase());
      });
    },

    openDropdownIfAutofocused() {
      if (this.autofocus) {
        this.openTrigger();
      }
    },

    openTrigger() {
      const popper = this.$refs.locSelect;
      if (popper) {
        return (popper as any).openTrigger();
      }
      return false;
    },

    closeTrigger() {
      const popper = this.$refs.locSelect;
      if (popper) {
        return (popper as any).closeTrigger();
      }
      return false;
    },

    onEscapeInput() {
      // @ts-expect-error types
      this.$refs['loc-input'].blur();
    },

    scrollToTop() {
      if (typeof document !== 'undefined') {
        const dropdown = document.querySelector(`.${this.componentId}`);
        if (dropdown !== null) {
          dropdown.scrollTo(0, 0);
        }
      }
    },

    onOpen() {
      this.isOpen = true;
      this.$emit('open');
    },

    onClose() {
      this.isOpen = false;
      this.$emit('close');

      const hasSomethingSelected = this.internalSelected.length > 0;
      if (this.singleSelection && hasSomethingSelected) {
        const hasModifiedSearch = this.selectedLabel !== this.internalSearch;
        if (hasModifiedSearch) {
          this.setSearch(this.internalSelected[0]?.label);
        }
      }
    },

    onClearInput() {
      this.setSearch('');
      this.focusInput();
    },

    focusInput() {
      // @ts-expect-error types
      this.$refs['loc-input'].focus();
    },
  },
});
</script>
