<template>
  <LocLazy v-if="renderLocLazy" :options="lazyLoadOptions.observerOptions" @loaded="onCellLazyLoaded" />
  <div v-else />
</template>

<script lang="ts">
import { TableLazyLoad } from '@/modules/@core/models/table-lazy-load';
import { defineComponent, PropType } from 'vue';
import LocLazy from '@/modules/@core/components/LocLazy/LocLazy.vue';
import { EventEmitterSingleton } from '@/modules/@core/services/event-emitter-singleton';

export interface IData {
  hasBeenObserved: boolean;
  unsubscribe: (..._args: any[]) => any;
}

const eventEmitter = EventEmitterSingleton.getInstance();

/** We need an element to be always visible regardless of whether you scrolled horizontally or not
 * to lazy load the whole road.
 * This component has zero width and uses position sticky to remain in the same position while being invisible.
 * However, that's enough to trigger the intersection observer and load the whole row
 */
export default defineComponent({
  name: 'LocDataTableRowLoader',

  components: {
    LocLazy,
  },

  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    lazyLoadOptions: {
      type: Object as PropType<Required<TableLazyLoad>>,
      required: true,
    },
  },

  data(): IData {
    return {
      hasBeenObserved: false,
      unsubscribe: () => undefined,
    };
  },

  computed: {
    renderLocLazy(): boolean {
      return this.lazyLoadOptions.enabled && !this.hasBeenObserved;
    },
  },

  mounted() {
    // load first five rows to have a "smoother" feeling
    if ([0, 1, 2, 3, 4].includes(this.rowIndex)) {
      this.hasBeenObserved = true;
    } else {
      this.unsubscribe = eventEmitter.subscribe(`observed-${this.rowIndex}`, this.hasBeenObservedResolver).unsubscribe;
    }
  },

  beforeDestroy() {
    this.unsubscribe();
  },

  methods: {
    onCellLazyLoaded() {
      eventEmitter.emit(`observed-${this.rowIndex}`, this.rowIndex);
    },

    hasBeenObservedResolver(rowIndex: number) {
      if (this.rowIndex === rowIndex) {
        this.hasBeenObserved = true;
      }
    },
  },
});
</script>
