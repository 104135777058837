<template>
  <div class="tw-flex">
    <div
      v-for="(bullet, index) in bulletCount"
      :key="bullet"
      data-test="loc-bullet"
      class="tw-rounded-full tw-w-2 tw-h-2 tw-mx-1"
      :class="bulletClasses(index)"
      @click="onBulletClick(index)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocBulletPagination',

  props: {
    value: {
      type: Number,
      required: true,
    },
    bulletCount: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    activeClasses: {
      type: String,
      default: 'tw-bg-primary',
    },
    inactiveClasses: {
      type: String,
      default: 'tw-bg-grey-lighten-3',
    },
    disabledActiveClasses: {
      type: String,
      default: 'tw-bg-grey',
    },
  },

  computed: {
    currentBullet: {
      get(): number {
        return this.value;
      },
      set(index: number): void {
        this.$emit('input', index);
      },
    },
  },

  methods: {
    bulletClasses(index: number): string {
      let classes = '';
      if (this.currentBullet === index) {
        classes = this.disabled ? this.disabledActiveClasses : this.activeClasses;
      } else {
        classes = this.inactiveClasses;
      }

      if (!this.disabled) {
        classes += ' tw-cursor-pointer';
      }
      return classes;
    },

    onBulletClick(index: number) {
      if (!this.disabled) {
        this.currentBullet = index;
      }
    },
  },
});
</script>
