<template>
  <Picker
    v-if="emojiIndex"
    v-show="open"
    :data="emojiIndex"
    set="twitter"
    :per-line="perLine"
    :max-search-results="maxSearchResults"
    :emoji-size="emojiSize"
    :title="title"
    :emoji="emoji"
    :color="color"
    :skin="skin"
    :default-skin="defaultSkin"
    :native="native"
    :emoji-tooltip="emojiTooltip"
    :auto-focus="autoFocus"
    :i18n="i18n"
    :show-preview="showPreview"
    :show-search="showSearch"
    :show-categories="showCategories"
    :show-skin-tones="showSkinTones"
    :infinite-scroll="infiniteScroll"
    :picker-styles="pickerStyles"
    @select="onSelect"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import * as emojiMartVue from 'emoji-mart-vue-fast';
import { Emoji } from '@/modules/@core/models/emoji';
import twitter from 'emoji-mart-vue-fast/data/twitter.json' with { type: 'json' };
const { Picker, EmojiIndex } = emojiMartVue;

export interface IData {
  loaded: boolean;
  emojiIndex: typeof EmojiIndex | null;
}

export default defineComponent({
  name: 'LocEmojiPicker',

  components: {
    Picker,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    eagerLoad: {
      type: Boolean,
      default: false,
    },
    perLine: {
      type: Number,
      default: 9,
    },
    maxSearchResults: {
      type: Number,
      default: 75,
    },
    emojiSize: {
      type: Number,
      default: 24,
    },
    title: {
      type: String,
      default: 'Localazy',
    },
    emoji: {
      type: String,
      default: 'department_store',
    },
    /** Top bar color */
    color: {
      type: String,
      default: '#ae65c5',
    },
    skin: {
      type: Number,
      default: null,
    },
    defaultSkin: {
      type: Number,
      default: 1,
    },
    native: {
      type: Boolean,
      default: false,
    },
    emojiTooltip: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    i18n: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    showPreview: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showCategories: {
      type: Boolean,
      default: true,
    },
    showSkinTones: {
      type: Boolean,
      default: true,
    },
    infiniteScroll: {
      type: Boolean,
      default: true,
    },
    pickerStyles: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
  },

  data(): IData {
    return {
      emojiIndex: null,
      loaded: false,
    };
  },

  watch: {
    set: {
      immediate: true,

      async handler() {
        if (!this.eagerLoad && !this.open && !this.loaded) {
          return;
        }
        await this.initializeForConfig();
      },
    },

    open: {
      async handler(open: boolean) {
        if (this.loaded) {
          return;
        }
        if (!this.eagerLoad && !open) {
          return;
        }
        await this.initializeForConfig();
      },
    },
  },

  methods: {
    async initializeForConfig() {
      const options = {
        // custom: [
        //   {
        //     name: 'Localazy',
        //     short_names: ['localazy'],
        //     text: '',
        //     emoticons: [],
        //     custom: true,
        //     imageUrl: 'https://emoji.slack-edge.com/TF31P5K5G/localazy/91b52b39636381c1.png',
        //   },
        // ],
      };

      this.emojiIndex = new EmojiIndex(twitter, options);
      this.loaded = true;
    },

    onSelect(emoji: Emoji) {
      this.$emit('select', emoji);
      if (emoji.native) {
        this.$emit('select-emoji', emoji.native);
      }
    },
  },
});
</script>

<style lang="postcss">
/* purgecss start ignore */
@import 'emoji-mart-vue-fast/css/emoji-mart.css';
/* purgecss end ignore */
</style>
