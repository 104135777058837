<template>
  <div
    class="loc-chip-filter tw-inline-flex tw-items-center tw-leading-none tw-border-solid tw-border tw-transition-colors tw-truncate tw-duration-150"
    :class="{
      'tw-h-8': large,
      'tw-h-6': !large && !small,
      'tw-h-5 tw-px-two tw-rounded-sm': small,
      'tw-rounded tw-px-1': !small,
      'tw-bg-primary-lighten-5 hover:tw-bg-primary-lighten-4 tw-text-secondary-darken-2': !active,
      'tw-bg-primary tw-border-primary tw-text-white': active,
      'tw-border-primary-lighten-4 hover:tw-tw-border-primary-lighten-3': !active && !darker,
      'tw-border-primary-lighten-2 hover:tw-tw-border-primary': !active && darker,
    }"
    @click="$emit('click', label)"
  >
    <div
      class="tw-truncate tw-flex tw-items-center tw-gap-1"
      :class="{
        'tw-px-1 tw-text-base': large,
        'tw-px-two tw-text-sm': !large,
      }"
    >
      <LocIcon v-if="active" name="done" :size="14" class="tw-flex-shrink-0 tw-fill-white" />
      <slot>
        {{ label }}
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export default defineComponent({
  name: 'LocChipFilter',

  components: {
    LocIcon,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    darker: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
