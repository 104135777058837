<script lang="ts">
import { defineComponent, VNode } from 'vue';

export default defineComponent({
  props: {
    slideClasses: {
      type: String,
      default: '',
    },
  },

  methods: {
    definedDefaultSlot(): VNode[] {
      const content = this.$slots.default ? this.$slots.default.filter((slot) => slot.tag !== undefined) : [];
      return content.map((slot) =>
        this.$createElement(
          'div',
          {
            attrs: {
              class: `swiper-slide ${this.slideClasses}`,
            },
          },
          [slot],
        ),
      );
    },
  },

  render(h): VNode {
    const content = this.definedDefaultSlot();
    return h('div', { attrs: { class: 'swiper-wrapper' } }, content);
  },
});
</script>

<style lang="postcss" scoped>
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: unset;
}
</style>
