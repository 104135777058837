<template>
  <div>
    <img v-if="isLogoResolved" :src="logoMapping[fileExtension]" />
    <span v-else>{{ fileExtension }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IntegrationService } from '@localazy/directus-service';

export interface IData {
  logoMapping: Record<string, string>;
}

export default defineComponent({
  name: 'FileIcon',

  props: {
    fileExtension: {
      type: String,
      required: true,
    },
  },

  data(): IData {
    return {
      logoMapping: {},
    };
  },

  computed: {
    isLogoResolved(): boolean {
      return !!this.logoMapping[this.fileExtension];
    },
  },

  async created() {
    const logos = await IntegrationService.fetchAllIntegrations({
      fields: 'slug,icon.data',
    });

    logos.forEach((logo) => {
      // @ts-expect-error types
      this.$set(this.logoMapping, logo.slug, logo.icon ? logo.icon.data.full_url : '');
    });
  },
});
</script>
