<script lang="ts">
import { IconDir } from '@/modules/@core/models/icon-dir';
import { resolveIconId } from '@/modules/@core/functions/resolve-icon-id';
import { rotateClass } from '@/modules/@core/functions/rotate-class';
import { defineComponent, PropType, VNode } from 'vue';
import '@localazy/svg-loader';

// noinspection TypeScriptValidateTypes
export default defineComponent({
  functional: true,

  props: {
    name: {
      type: String,
      required: true,
    },
    dir: {
      type: String as PropType<IconDir>,
      default: 'up',
      validator: (dir: IconDir) => ['up', 'down', 'left', 'right', 'top-right', 'bottom-right'].includes(dir),
    },
    size: {
      type: Number,
      default: 24,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },

  render(h, { props, data, listeners }): VNode {
    // Generate data-src attribute value
    const dataSrc = `https://directus9.localazy.com/assets/${resolveIconId(props.name)}.svg`;

    // Compute the class list for the componentnp
    const classes = ['tw-transform', data.class || '', data.staticClass || '', rotateClass(props.dir)];

    return h('svg', {
      attrs: {
        'data-src': dataSrc,
        'data-loading': 'lazy',
        'data-cache': '31556952000',
        'data-name': props.name,
        height: props.size,
        width: props.size,
        ...(data.attrs || {}),
      },
      class: classes,
      style: data.style,
      on: listeners,
    });
  },
});
</script>
