<template>
  <div ref="locDataTable" class="tw-w-full tw-h-full tw-flex tw-flex-col tw-max-h-screen">
    <div
      class="table-grid tw-w-full tw-grid tw-overflow-x-auto tw-auto-rows-max"
      :class="allTableClasses"
      :style="[gridCSSVariables]"
    >
      <!-- Adjust Grid for hidden lazy loader on each row -->
      <!-- To keep the number of elements in the header row the same as in the following row -->
      <div class="tw-contents tw-sticky tw-top-0 tw-left-0">
        <div v-if="!hideHeader" />
        <LocDataTableHeaderMultiselect
          v-if="multiSelect && !hideHeader"
          :class="[{ 'header-shadow': !shouldShowBatchOptions }]"
          class="tw-top-0 tw-sticky tw-left-0 tw-z-40"
          :all-on-page-checked="allOnPageChecked"
          :some-on-page-checked="someOnPageChecked"
          :disabled="isEmptyTable"
          @input="(shouldSelectAll) => onSelectAllOnPage(shouldSelectAll)"
        />
        <LocDataTableHeaderColumn
          v-for="(header, index) in hideHeader ? [] : headers"
          :ref="`header${index}`"
          :key="header.key"
          class="tw-z-30 tw-sticky tw-top-0"
          :class="[
            header.headerClass || headerClasses,
            freezeColumnClasses(index, 'header'),
            { 'tw-border-r-0': index === headers.length - 1 },
            { 'header-shadow': !shouldShowBatchOptions },
          ]"
          :header-key="header.key"
          :sortable="header.sortable || false"
          :label="header.label || ''"
          :sort.sync="resolvedSort"
          :set-query="setQuery"
          @mouseenter="headerRowHovered = true"
          @mouseleave="headerRowHovered = false"
        >
          <template #[`header-${header.key}`]>
            <slot :name="`header-${header.key}`" :header="header" :header-row-hovered="headerRowHovered" />
          </template>
          <template #[`header-${header.key}-sort-icon`]>
            <slot
              v-if="$slots[`header-${header.key}-sort-icon`]"
              :name="`header-${header.key}-sort-icon`"
              :sort-order="resolvedSort.sortOrder"
            />
            <slot v-else :name="`header-${index}-sort-icon`" :sort-order="resolvedSort.sortOrder" />
          </template>
          <template #[`header-${header.key}-right`]>
            <slot v-if="$slots[`header-${header.key}-right`]" :name="`header-${header.key}-right`" :row="header" />
            <slot v-else :name="`header-${index}-right`" :row="header" />
          </template>
        </LocDataTableHeaderColumn>
        <LocTransitionExpand>
          <div
            v-show="shouldShowBatchOptions"
            ref="batchArea"
            class="tw-bg-primary-lighten-5 tw-border-b tw-border-t tw-border-grey-lighten-4 tw-min-h-12 xl:tw-min-h-14 header-shadow tw-z-20 tw-sticky tw-w-full tw-left-0 tx-py-1 md:tx-py-0"
            :class="batchAreaClasses"
            :style="{ 'grid-column': `1 / span ${columnsCount}` }"
          >
            <LocDataTableSelectionOptions
              data-test="loc-data-table-batch"
              :selected="value"
              :filtered-rows="filteredRows"
              :row-unique-attribute="rowUniqueAttribute"
              @select-all="onSelectAll"
              @clear-all="onClearAll"
            >
              <template #clear-select-adjacent>
                <slot name="clear-select-adjacent" />
              </template>
            </LocDataTableSelectionOptions>
          </div>
        </LocTransitionExpand>
        <LocProgress
          v-if="loading"
          indeterminate
          :style="{ 'grid-column-end': `span ${columnsCount}` }"
          :height="3"
          color="primary-lighten-1"
          secondary-color="primary-lighten-3"
        />
      </div>

      <!-- Be aware that display: grid affects this -->
      <slot name="below-header" :columns-count="columnsCount" />

      <div
        v-if="computedRows.length === 0"
        data-test="loc-data-table-empty"
        class="tw-flex tw-justify-center tw-text-grey-lighten-1"
        :style="{ 'grid-column-end': `span ${columnsCount}` }"
      >
        <slot v-if="loading" name="empty-loading-state">
          <div class="tw-my-24">
            {{ t('LocDataTable.loading') }}
          </div>
        </slot>
        <slot v-else name="empty-state">
          <div class="tw-my-24">
            {{ t('LocDataTable.no-data-to-display') }}
          </div>
        </slot>
      </div>
      <div class="tw-overflow-y-auto tw-contents" data-test="loc-data-table-rows-wrapper">
        <template v-for="(row, rowIndex) in computedRows">
          <div :key="`${rowIndex}-wrapper`" class="table-row-wrapper tw-contents">
            <LocDataTableRowLoader
              :lazy-load-options="fullLazyLoadOptions"
              :row-index="rowIndex"
              class="tw-sticky tw-left-0"
            />
            <div
              v-if="multiSelect"
              class="tw-flex tw-border tw-border-t-0 tw-border-l-0 tw-py-4 tw-px-3 xl:tw-px-4 tw-z-10 tw-border-solid tw-border-grey-lighten-4 tw-sticky tw-left-0 tw-bg-white"
              :class="[
                resolveCellClass(row),
                {
                  'tw-items-center': !topAligned,
                  'tw-items-start': topAligned,
                },
              ]"
              :data-row-id="row[rowUniqueAttribute]"
              data-column-key="loc-data-table-select-box"
            >
              <LocCheckbox
                :value="isSelected(row)"
                type="checkbox"
                class="tw-flex tw-justify-center"
                data-test="loc-data-table-select-box"
                :class="{
                  'tw-w-full tw-h-full': !topAligned,
                  'xl:tw-py-1': topAligned,
                }"
                @input="(isNowSeleted) => onSelect(row, isNowSeleted)"
              />
            </div>
            <LocDataTableCell
              v-for="(header, colIndex) in headers"
              :key="`${rowIndex} - ${colIndex}`"
              :class="[
                freezeColumnClasses(colIndex, 'cell'),
                {
                  'tw-border-r-0': colIndex === headers.length - 1 || header.noBorder,
                },
              ]"
              :data-row-id="row[rowUniqueAttribute]"
              :data-column-index="colIndex"
              :data-column-key="headers[colIndex].key"
              data-test="loc-data-table-cell"
              :header-key="header.key.toString()"
              :row-index="rowIndex"
              :lazyload-enabled="fullLazyLoadOptions.enabled"
              :is-link="isValidRowLink(header, row)"
              :tooltip="resolveTooltip(header, row)"
              :link="header.link"
              :top-aligned="topAligned"
              :cell-classes="`${header.cellClasses || cellClasses} ${resolveCellClass(row)}`"
              :has-click-event="hasClickEvent(header)"
              :row="row"
              @click="hasClickEvent(header) ? header.onClick($event) : undefined"
            >
              <template #[`column-${header.key}`]>
                <slot :name="`column-${header.key}-prepend`" :row="row" :cell="row[header.key]" />

                <slot :name="`column-${header.key}`" :row="row" :cell="row[header.key]">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-if="header.filterable" v-html="highlightSearchValue(row[header.key])" />
                  <span v-else>{{ row[header.key] }}</span>
                </slot>
              </template>
            </LocDataTableCell>
          </div>
        </template>
      </div>
    </div>

    <slot name="above-footer" :is-empty="computedRows.length === 0" />

    <!-- fixed footer avoiding collapse with mobile menu as default  -->
    <LocDataTableFooter
      v-if="computedPagination.enabled"
      :pagination.sync="computedPagination"
      :total-items="serverRowsLength || filteredRows.length"
      class="tw-mt-auto tw-rounded-b tw-bg-white"
      :class="{
        'footer-shadow tw-sticky tw-bottom-nav tw-bottom-nav sm:tw-bottom-0 tw-z-20': footerSticky,
        'tw-border-t tw-border-grey-lighten-3': !footerSticky,
      }"
    />
  </div>
</template>

<script lang="ts">
import { CellTooltip } from '@/modules/@core/models/cell-tooltip';
import { ComponentData } from '@/modules/@core/models/component-data';
import { Header } from '@/modules/@core/models/header';
import { Row } from '@/modules/@core/models/row';
import { Sort } from '@/modules/@core/models/sort';
import { SortOrder } from '@/modules/@core/enums/sort-order';
import { TableLazyLoad } from '@/modules/@core/models/table-lazy-load';
import { TablePagination } from '@/modules/@core/models/table-pagination';
import { defineComponent, PropType } from 'vue';
import { merge, unionBy, differenceBy, intersection, indexOf, filter, debounce } from 'lodash-es';
import { locDataTableDefaultComparator } from '@/modules/@core/functions/data-table/loc-data-table-default-comparator';
import { locDataTablePaginate } from '@/modules/@core/functions/data-table/loc-data-table-paginate';
import { locDataTableFilterRows } from '@/modules/@core/functions/data-table/loc-data-table-filter-rows';
import LocCheckbox from '@/modules/@core/components/LocCheckbox/LocCheckbox.vue';
import LocDataTableFooter from '@/modules/@core/components/LocDataTable/footer/LocDataTableFooter.vue';
import LocDataTableSelectionOptions from '@/modules/@core/components/LocDataTable/header/LocDataTableSelectionOptions.vue';
import LocDataTableHeaderMultiselect from '@/modules/@core/components/LocDataTable/header/LocDataTableHeaderMultiselect.vue';
import LocDataTableHeaderColumn from '@/modules/@core/components/LocDataTable/header/LocDataTableHeaderColumn.vue';
import LocDataTableRowLoader from '@/modules/@core/components/LocDataTable/LocDataTableRowLoader.vue';
import LocProgress from '@/modules/@core/components/LocProgress/LocProgress.vue';
import LocDataTableCell from '@/modules/@core/components/LocDataTable/LocDataTableCell.vue';
import LocTransitionExpand from '@/modules/@core/components/LocTransitionExpand/LocTransitionExpand.vue';
import { langService } from '@/modules/translations/const/lang-service';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';

export default defineComponent({
  name: 'LocDataTable',

  components: {
    LocCheckbox,
    LocDataTableFooter,
    LocDataTableSelectionOptions,
    LocDataTableHeaderMultiselect,
    LocDataTableHeaderColumn,
    LocDataTableCell,
    LocProgress,
    LocTransitionExpand,
    LocDataTableRowLoader,
  },

  props: {
    /**
     * Selected rows
     */
    value: {
      type: Array as PropType<Row[]>,
      default: () => [],
    },
    headers: {
      type: Array as PropType<Header[]>,
      required: true,
    },
    /**
     * Only columns presented in the header keys are shown
     */
    rows: {
      type: Array as PropType<Row[]>,
      required: true,
    },
    /**
     * All rows used for refreshing `value` (e.g. after some action)
     */
    allRows: {
      type: Array as PropType<Row[]>,
      required: true,
    },
    serverRowsLength: {
      type: Number,
      default: 0,
    },
    /**
     * Unique attribute of each row (i.e. primary key)
     */
    rowUniqueAttribute: {
      type: String,
      default: 'id',
    },
    /**
     * Search term applicable to filterable columns
     */
    search: {
      type: String,
      default: '',
    },
    /**
     * Override default search function with a custom one
     */
    customSearchFunction: {
      type: Function as PropType<(_rows: Row[], _search: string, _headers: Header[]) => Row[]>,
      default: undefined,
    },
    pagination: {
      type: Object as PropType<Partial<TablePagination>>,
      default: undefined,
    },
    /**
     * Sort options for sortable headers
     */
    sort: {
      type: Object as PropType<Sort>,
      default: (): Sort => ({ sortBy: null, sortOrder: SortOrder.UNSORTED }),
    },
    /**
     * Add checkboxes to rows and enable them to be selectable
     */
    multiSelect: {
      type: Boolean,
      default: false,
    },
    /**
     * Show loading state when true
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Classes applied to each header cell
     */
    headerClasses: {
      type: String,
      default: 'tw-px-3 xl:tw-px-4',
    },
    /**
     * Classes applied to the table wrapper
     */
    tableClasses: {
      type: String,
      default: '',
    },
    /**
     * Classes of each indiviual cell
     */
    cellClasses: {
      type: String,
      default: 'tw-p-3 xl:tw-p-4 tw-mt-two',
    },
    /**
     * Classes applied to batch area wrapper
     * Only applicable when multiselect is true
     */
    batchAreaClasses: {
      type: String,
      default: '',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    lazyLoadOptions: {
      type: Object as PropType<TableLazyLoad>,
      default: () => ({}),
    },
    setQuery: {
      type: Boolean,
      default: true,
    },
    /**
     * Adding an option to align cells to top
     */
    topAligned: {
      type: Boolean,
      default: true,
    },
    freezeColumns: {
      type: Object as PropType<{ left: number; right: 0 }>,
      default: () => ({ left: 0, right: 0 }),
    },
    /** Sticky footer - not recommended causes unituitive UX */
    footerSticky: {
      type: Boolean,
      default: false,
    },
  },

  data(): ComponentData {
    return {
      localSort: this.sort,
      localPagination: {
        enabled: true,
        page: 1,
        pageSizeOptions: [10, 25, 50, 100],
        pageSize: 10,
      },
      resizeObserver: null,
      headerRowHovered: false,
    };
  },

  computed: {
    resolvedSort: {
      get(): Sort {
        return isPropSet(this.$options, 'sort') ? this.sort : this.localSort;
      },
      set(sort: Sort) {
        this.localSort = sort;
        this.$emit('update:sort', sort);
      },
    },
    gridCSSVariables() {
      /** We're adding conditionally some columns which makes
       * math behind grids non-trivial. In JS we factor the appropriate grid class
       * and attach it as CSS variable to the grid container
       */
      let gridTemplateColumns = '0px';

      if (this.multiSelect) {
        gridTemplateColumns = `${gridTemplateColumns} min-content`;
      }

      this.headers.forEach((header) => {
        const minColumnWidth = header.minWidth || '200px';
        if (header.autoWidth) {
          gridTemplateColumns += ' auto ';
        } else {
          gridTemplateColumns += ` minmax(${minColumnWidth},${header.relativeWidth || 1}fr)`;
        }
      });

      return {
        '--loc-data-table-template-columns': gridTemplateColumns,
      };
    },
    computedPagination: {
      get(): TablePagination {
        if (this.pagination) {
          return { ...this.localPagination, ...this.pagination };
        }
        return this.localPagination;
      },
      set(val: TablePagination) {
        this.localPagination = val;
        this.$emit('update:pagination', val);
      },
    },
    filteredRows(): Row[] {
      if (this.search !== '') {
        return this.customSearchFunction
          ? this.customSearchFunction(this.rows, this.search, this.headers)
          : locDataTableFilterRows(this.rows, this.search, this.headers);
      }
      return [...this.rows];
    },
    computedRows(): Row[] {
      const rows: Row[] = [...this.filteredRows];
      if (this.resolvedSort.sortBy && this.resolvedSort.sortOrder) {
        if (this.sortHeader) {
          const { comparator, sortAttribute } = this.sortHeader;
          const compareFunction =
            comparator || locDataTableDefaultComparator.bind(null, sortAttribute, this.resolvedSort.sortBy);
          rows.sort((a, b) => compareFunction(a, b) * this.resolvedSort.sortOrder);
        }
      }
      if (this.computedPagination.enabled) {
        return locDataTablePaginate(rows, this.computedPagination.pageSize, this.computedPagination.page);
      }
      return rows;
    },
    computedSelectedRowsUniqueAttributes(): unknown[] {
      if (!this.value.length) {
        return [];
      }

      return this.value.map((row) => row[this.rowUniqueAttribute]);
    },
    computedRowsUniqueAttributes(): unknown[] {
      if (!this.computedRows.length) {
        return [];
      }

      return this.computedRows.map((row) => row[this.rowUniqueAttribute]);
    },
    computedPageSelectedRowsUniqueAttributes(): unknown[] {
      if (!this.value.length) {
        return [];
      }

      const computedRowsIds = this.computedRowsUniqueAttributes;
      const valueIds = this.computedSelectedRowsUniqueAttributes;

      const result = intersection(computedRowsIds, valueIds);
      return result;
    },
    allTableClasses(): string {
      const classes = this.tableClasses;
      return classes;
    },
    sortHeader(): Header | undefined {
      return this.headers.find((h) => h.key === this.resolvedSort.sortBy);
    },
    allOnPageChecked(): boolean {
      if (!this.value.length || this.isEmptyTable) return false;

      const computedRowsIds = this.computedRowsUniqueAttributes;
      const valueIds = this.computedSelectedRowsUniqueAttributes;

      const intersect = intersection(computedRowsIds, valueIds);

      return intersect.length === computedRowsIds.length;
    },
    someOnPageChecked(): boolean {
      if (!this.value.length || this.isEmptyTable) return false;

      const computedRowsIds = this.computedRowsUniqueAttributes;
      const valueIds = this.computedSelectedRowsUniqueAttributes;
      const intersect = intersection(computedRowsIds, valueIds);

      return !!intersect.length;
    },
    isEmptyTable(): boolean {
      return this.computedRows.length === 0;
    },
    columnsCount(): number {
      // 1 for the 0px column
      return 1 + this.headers.length + (this.multiSelect ? 1 : 0);
    },
    fullLazyLoadOptions(): Required<TableLazyLoad> {
      return merge(
        {},
        {
          enabled: false,
          observerOptions: {
            root: null, // defaults to browser viewport
            rootMargin: '0px 0px 0px 0px',
            threshold: 0,
          },
          tag: 'div',
          transition: 'loc-fade',
        },
        this.lazyLoadOptions,
      );
    },
    shouldShowBatchOptions(): boolean {
      return this.multiSelect && !!this.value.length;
    },
  },

  watch: {
    allRows(newRows: Row[]) {
      const valueIds = this.computedSelectedRowsUniqueAttributes;
      const refreshedSelectedRows = filter(newRows, (newRow) => valueIds.includes(newRow[this.rowUniqueAttribute]));

      this.$emit('input', refreshedSelectedRows);
    },

    shouldShowBatchOptions(newVal: boolean) {
      if (newVal) {
        this.setupResizeListener();
        this.$nextTick(() => {
          this.handleResize();
        });
      } else {
        this.cleanupResizeListener();
      }
    },
  },

  beforeDestroy() {
    this.cleanupResizeListener();
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },

    tc(key: string, count: number) {
      return langService.tc(key, count);
    },

    onSelectAll() {
      this.$emit('input', unionBy(this.filteredRows, this.value, this.rowUniqueAttribute));
    },

    onClearAll() {
      this.$emit('input', []);
    },

    onSelectAllOnPage(shouldSelectAll: boolean) {
      if (shouldSelectAll) {
        // Keep all selected and any that are not selected that are on this page
        this.$emit('input', unionBy(this.computedRows, this.value, this.rowUniqueAttribute));
      } else {
        // Remove those from the selected list that are on the page
        this.$emit('input', differenceBy(this.value, this.computedRows, this.rowUniqueAttribute));
      }
    },

    isSelected(row: Row) {
      const uniqueRowAtribute = row[this.rowUniqueAttribute];
      return this.computedPageSelectedRowsUniqueAttributes.find((id) => id === uniqueRowAtribute) !== undefined;
    },

    onSelect(row: Row, isNowSelected: boolean) {
      if (isNowSelected) {
        this.$emit('input', [...this.value, row]);
      } else {
        const index = indexOf(this.value, row);
        const newSelection = this.value;
        newSelection.splice(index, 1);
        this.$emit('input', newSelection);
      }
    },

    isValidRowLink(header: Header, row: Row) {
      return !!header.link && header.link(row as never) !== undefined;
    },

    resolveTooltip(header: Header, row: Row): CellTooltip {
      if (header.cellTooltip?.(row)) {
        return header.cellTooltip(row);
      }
      return {
        content: '',
      };
    },

    hasClickEvent(header: Header): header is Header & { onClick: () => void } {
      return header.onClick !== undefined;
    },

    highlightSearchValue(value: string | number) {
      if (this.search === '' || !value) {
        return value;
      }
      const stringValue = value.toString();
      const regex = new RegExp(`(${this.search})`, 'gi');
      return stringValue.replace(regex, '<span class="tw-bg-warning-lighten-4">$1</span>');
    },

    resolveCellClass(row: Row) {
      const { cellClass } = row;
      if (typeof cellClass === 'string') {
        return cellClass;
      }

      if (typeof cellClass === 'function') {
        return cellClass(row);
      }

      return '';
    },

    freezeColumnClasses(index: number, type: 'header' | 'cell') {
      const colIndex = index + 1;
      const shouldFreezeLeft = colIndex <= this.freezeColumns.left;
      const shouldFreezeRight = colIndex > this.headers.length - this.freezeColumns.right;
      if (shouldFreezeLeft || shouldFreezeRight) {
        const zIndexClass = type === 'header' ? 'tw-z-20' : 'tw-z-10';
        const sideClass = shouldFreezeLeft ? 'tw-left-0' : 'tw-right-0 tw-border-l tw--ml-px';
        return `tw-sticky ${sideClass} ${zIndexClass}`;
      }
      return '';
    },

    handleResize() {
      const batchArea = this.$refs.batchArea as HTMLElement;
      if (batchArea) {
        // const width = (this.$refs.locDataTable as HTMLElement)?.clientWidth || 0;
        let headerHeight = 0;
        if (!this.hideHeader) {
          headerHeight =
            ((this.$refs.header0?.[0] as InstanceType<typeof LocDataTableHeaderColumn>)?.$el as HTMLElement)
              .offsetHeight || 0;
        }
        // batchArea.style.maxWidth = `${width}px`;
        batchArea.style.top = `${headerHeight}px`;
      }
    },

    setupResizeListener() {
      const debouncedResize = debounce(this.handleResize, 250);
      window.addEventListener('resize', debouncedResize);
      this.resizeObserver = debouncedResize;
    },

    cleanupResizeListener() {
      if (this.resizeObserver) {
        window.removeEventListener('resize', this.resizeObserver);
        this.resizeObserver = null;
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
.table-grid {
  grid-template-columns: var(--loc-data-table-template-columns);
}
.header-shadow {
  box-shadow: var(--shadow-header);
}

.footer-shadow {
  box-shadow: var(--shadow-footer);
}

/* purgecss start ignore */
.table-row-wrapper > div {
  background-color: var(--white);
}
.table-row-wrapper:hover > div {
  background-color: var(--grey-lighten-5);
}
/* purgecss end ignore */
</style>
