<template>
  <LocBadge
    bottom
    right
    color="white"
    :hidden="projectType === ProjectType.Public || projectType === ProjectType.Demo"
    wrapper-classes="tw-border-2 tw-border-solid tw-border-secondary-lighten-4 tw-mr-1 tw-mb-1"
  >
    <template #badge>
      <LocTooltip :skidding="10">
        <LocIcon
          class="tw-fill-secondary-lighten-1"
          :size="lockIconSize"
          data-test="loc-app-avatar-badge-icon"
          :name="projectType === ProjectType.Private ? 'lock-outline' : 'incognito'"
        />
        <template #content>
          <span v-if="projectType === ProjectType.Private">
            {{ t('LocAppAvatar.private_project') }}
          </span>
          <span v-if="projectType === ProjectType.Secret">
            {{ t('LocAppAvatar.secret_project') }}
          </span>
        </template>
      </LocTooltip>
    </template>
    <div
      data-test="app-avatar"
      class="tw-relative tw-text-white tw-rounded tw-overflow-hidden"
      :class="{
        'tw-cursor-pointer': showUploadIcon,
        'tw-border-2 tw-border-solid tw-border-primary-lighten-4': border,
      }"
      :style="{
        width: dimension,
        height: dimension,
        fontSize: `${fontSize}px`,
      }"
      @mouseenter="onAvatarEnter"
      @mouseleave="onAvatarLeave"
      @click="onAvatarClick"
    >
      <slot name="clickable-overlay" :hovered="hovered">
        <LocAvatarOverlay v-if="showUploadIcon" data-test="upload-overlay" :hovered="hovered" :size="size" />
      </slot>
      <img
        data-test="image"
        :src="image"
        :alt="name"
        class="tw-object-cover tw-object-center"
        :style="{
          width: '100%',
          height: '100%',
        }"
      />
    </div>
  </LocBadge>
</template>

<script lang="ts">
import { generateAppAvatarNode } from '@/modules/@core/functions/avatar/generate-app-avatar-node';
import { defineComponent, PropType } from 'vue';
import { isBrowser } from '@localazy/core';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocBadge from '@/modules/@core/components/LocBadge/LocBadge.vue';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';
import LocAvatarOverlay from '@/modules/@core/components/LocAvatarOverlay/LocAvatarOverlay.vue';
import { ProjectType } from '@/modules/@core/enums/project-type';
import { generateAppAvatarBrowser } from '@/modules/@core/functions/avatar/generate-app-avatar-browser';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';
import { langService } from '@/modules/translations/const/lang-service';

export interface IData {
  hovered: boolean;
  localImage: string;
  ProjectType: typeof ProjectType;
}

const EMPTY_NAME_SEED = 'We love Localazy!';

export default defineComponent({
  components: {
    LocIcon,
    LocBadge,
    LocTooltip,
    LocAvatarOverlay,
  },

  props: {
    /**
     * Optional image, if undefined random avatar will be displayed instead
     */
    img: {
      type: String,
      default: '',
    },
    /**
     * Name will be shortened to two letter initials
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Size in px
     */
    size: {
      type: Number,
      default: 96,
    },
    /**
     * Whether to show upload icon on hover
     */
    showUploadIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Project type determines avatar's badge
     */
    projectType: {
      type: Number as PropType<ProjectType>,
      default: 1,
    },
    /**
     * Control avatar box shadow appearance
     */
    border: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      hovered: false,
      localImage: this.img,
      ProjectType,
    };
  },

  computed: {
    image: {
      get(): string {
        return isPropSet(this.$options, 'img') && this.hasImage ? this.img : this.localImage;
      },
      set(value: string) {
        if (value !== this.localImage) {
          this.$emit('update:img', value);
          this.localImage = value;
        }
      },
    },
    hasImage(): boolean {
      return !!this.img;
    },
    fontSize(): number {
      return this.size * 0.5;
    },
    dimension(): string {
      return `${this.size}px`;
    },
    lockIconSize(): number {
      const lockSize = 0.3 * this.size;
      return lockSize < 12 ? 12 : lockSize;
    },
    initials(): string {
      if (this.name) {
        const acronym = this.name.split(' ').map((word) => word[0]);
        acronym.splice(1, acronym.length - 2);
        return acronym.join('').toUpperCase();
      }
      return '';
    },
  },

  watch: {
    name() {
      if (!this.hasImage) {
        this.generateAvatar();
      }
    },
  },

  created() {
    if (!this.hasImage) {
      this.generateAvatar();
    }
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },

    onAvatarEnter() {
      this.hovered = true;
    },

    onAvatarLeave() {
      this.hovered = false;
    },

    onAvatarClick() {
      this.$emit('click');
    },

    async generateAvatar(randomize = false) {
      if (isBrowser) {
        this.image = await generateAppAvatarBrowser(this.name || EMPTY_NAME_SEED, randomize);
      } else {
        this.image = generateAppAvatarNode(this.name || EMPTY_NAME_SEED, randomize);
      }
    },
  },
});
</script>
