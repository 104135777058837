<template>
  <div ref="wrapper" class="tw-overflow-auto">
    <slot :render-value="renderValue" />
    <div ref="scrollBottom">
      <div
        v-if="shouldShowMore"
        ref="loadMore"
        class="tw-relative tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2"
      >
        <slot name="load-more">
          <LocLoadingSpinner :size="1" :thickness="1.5" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocLoadingSpinner from '@/modules/@core/components/LocLoadingSpinner/LocLoadingSpinner.vue';

export interface IData {
  renderValue: unknown[];
}

let observer: IntersectionObserver;

export default defineComponent({
  name: 'LocInfiniteScrollWrapper',

  components: {
    LocLoadingSpinner,
  },

  props: {
    value: {
      type: Array as () => unknown[],
      default: () => [],
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    threshold: {
      type: Number,
      default: 0,
    },
    appendCount: {
      type: Number,
      default: 20,
    },
    maxAllowedHeight: {
      type: Number,
      default: 500,
    },
  },

  data(): IData {
    return {
      renderValue: this.value.slice(0, this.appendCount),
    };
  },

  computed: {
    isAllRendered(): boolean {
      return this.renderValue.length === this.value.length;
    },
    shouldShowMore(): boolean {
      return this.showMore && !this.isAllRendered;
    },
    wrapper(): HTMLElement {
      return this.$refs.wrapper as HTMLElement;
    },
  },

  watch: {
    value: {
      handler() {
        this.renderValue = this.value.slice(0, this.appendCount);

        this.$nextTick(() => {
          const scrollBottom = this.$refs.scrollBottom as HTMLElement;
          const isAtBottom = this.wrapper.scrollTop + this.wrapper.clientHeight >= scrollBottom.offsetTop;

          if (isAtBottom && this.shouldShowMore) {
            const children = Array.from(this.wrapper.children).filter((child) => child !== this.$refs.scrollBottom);
            const childrenHeight = children.reduce((acc, child: Element) => acc + child.clientHeight, 0);
            const showMoreHeight = this.shouldShowMore ? (this.$refs.loadMore as HTMLElement).clientHeight : 0;
            this.wrapper.scrollTop = childrenHeight - this.wrapper.clientHeight - showMoreHeight;
          }
          this.setWrapperHeight();
        });
      },
    },
  },

  mounted() {
    this.wrapper.style.maxHeight = `${this.maxAllowedHeight}px`;

    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.loadMore();
          }
        });
      },
      { root: this.wrapper, threshold: this.threshold },
    );

    observer.observe(this.$refs.scrollBottom as HTMLElement);
  },

  beforeDestroy() {
    observer.disconnect();
  },

  methods: {
    setWrapperHeight() {
      const children = Array.from(this.wrapper.children).filter(
        (child) => child !== this.$refs.scrollBottom,
      ) as HTMLElement[];
      const childrenHeight = children.reduce((acc, child: HTMLElement) => acc + child.offsetHeight, 0);
      const computedWrapperHeight = childrenHeight < this.maxAllowedHeight ? childrenHeight : this.maxAllowedHeight;
      this.wrapper.style.height = `${Math.min(this.maxAllowedHeight, computedWrapperHeight)}px`;
    },

    loadMore() {
      this.renderValue = this.value.slice(0, this.renderValue.length + this.appendCount);
      this.$emit('load-more');
    },
  },
});
</script>
