<template>
  <component
    :is="componentName"
    :[linkAttr]="linkValue"
    :target="blank ? '_blank' : null"
    :rel="isExternalLink ? 'noopener' : null"
    data-test="loc-button"
    class="loc-button tw-relative tw-select-none tw-no-underline tw-tracking-wide tw-inline-flex tw-uppercase tw-font-medium tw-outline-none tw-items-center tw-justify-center tw-cursor-pointer tw-transition-colors tw-ease-out tw-duration-150 tw-border tw-border-solid focus:tw-border-primary-lighten-3 focus:tw-border-2"
    :class="[
      {
        'tw-pointer-events-none': disabled || isLoading,
        'tw-h-6 tw-text-xs': tiny,
        'tw-h-8 tw-text-xs': small,
        'tw-h-10 tw-text-sm': !small && !large && !tiny,
        'tw-h-12 tw-text-base': large,
        'tw-h-14 tw-text-base': xl,
        'tw-rounded': !block,
      },
      widthClass,
    ]"
    :style="{
      '--bg-color': bgColor,
      '--hover-color': hoverColor,
      '--active-color': activeColor,
      '--font-color': fontColor,
      '--border-color': borderColor,
    }"
    :aria-disabled="disabled ? 'true' : 'false'"
    @click="onClick"
  >
    <div
      v-if="isLoading"
      class="tw-absolute tw-top-0 tw-left-0 tw-z-50 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
      data-test="loc-button-spinner"
    >
      <LocLoadingSpinner
        :color="secondary || tertiary || transparent ? color : 'white'"
        :size="spinnerProps.size"
        :thickness="spinnerProps.thickness"
      />
    </div>
    <LocIcon
      v-if="icon"
      :name="icon"
      :dir="iconDir"
      class="tw-flex-shrink-0"
      :class="{
        'tw-invisible': isLoading,
        'tw-visible': !isLoading,
      }"
      :style="{ fill: fontColor }"
      :size="iconSize"
    />
    <span
      v-else
      :class="`${isLoading ? 'tw-invisible' : 'tw-visible'} ${block ? 'tw-w-full tw-h-full' : ''}`"
      class="tw-leading-none tw-flex tw-items-center"
    >
      <slot />
    </span>
  </component>
</template>

<script lang="ts">
import { cssVar } from '@/modules/@core/functions/css-var';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocLoadingSpinner from '@/modules/@core/components/LocLoadingSpinner/LocLoadingSpinner.vue';
import { ISpinnerPropsObject } from '@/modules/@core/models/i-spinner-props-object';
import { IconDir } from '@/modules/@core/models/icon-dir';
import { defineComponent, PropType } from 'vue';

const DEFAULT_BUTTON_SPINNER_PROPS: ISpinnerPropsObject = {
  size: 1.25,
  thickness: 2,
};

const LARGE_BUTTON_SPINNER_PROPS: ISpinnerPropsObject = {
  size: 1.5,
  thickness: 2,
};

const SMALL_BUTTON_SPINNER_PROPS: ISpinnerPropsObject = {
  size: 1,
  thickness: 1,
};

export default defineComponent({
  name: 'LocButton',

  components: {
    LocLoadingSpinner,
    LocIcon,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /** Minimal button size  */
    tiny: {
      type: Boolean,
      default: false,
    },
    /** Small button size, use in less improtant and more complex UI.  */
    small: {
      type: Boolean,
      default: false,
    },
    /** Enlarged button size. Mainly use on website and for simple CTAs.  */
    large: {
      type: Boolean,
      default: false,
    },
    /** Enlarged button size with full height. Used for table actions.  */
    xl: {
      type: Boolean,
      default: false,
    },
    /** Used for less important actionables.  */
    secondary: {
      type: Boolean,
      default: false,
    },
    /** Used for tertiary actions and ordinary UI interactive parts  */
    tertiary: {
      type: Boolean,
      default: false,
    },
    /** Tertiary button with a very light bg color.  */
    tertiaryColorful: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    /** This will turn button element into a icon button. Text label will be ignored.  */
    icon: {
      type: String,
      default: '',
    },
    iconDir: {
      type: String as PropType<IconDir>,
      default: 'up',
      validator: (dir: string) => ['up', 'down', 'left', 'right'].includes(dir),
    },
    to: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    blank: {
      type: Boolean,
      default: false,
    },
    /** Inverted version of the button with colored text and border and transparent bg.  */
    transparent: {
      type: Boolean,
      default: false,
    },
    /** Remove rounded corners and fill available space. */
    block: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isExternalLink: false,
    };
  },

  computed: {
    componentName(): string {
      if (this.to) {
        return 'router-link';
      }
      if (this.href) {
        return 'a';
      }
      return 'div';
    },
    linkAttr(): string {
      if (this.to) {
        return 'to';
      }
      if (this.href) {
        return 'href';
      }
      return '';
    },
    linkValue(): string | null {
      return this.to || this.href || null;
    },
    spinnerProps(): ISpinnerPropsObject {
      if (this.small) {
        return SMALL_BUTTON_SPINNER_PROPS;
      }
      if (this.large) {
        return LARGE_BUTTON_SPINNER_PROPS;
      }
      return DEFAULT_BUTTON_SPINNER_PROPS;
    },
    bgColor(): string {
      if (this.transparent) {
        return cssVar('transparent');
      }
      if (this.disabled) {
        return this.secondary || this.tertiary || this.tertiaryColorful
          ? cssVar('grey-lighten-5')
          : cssVar('grey-lighten-3');
      }
      if (this.secondary) {
        return cssVar('white');
      }
      if (this.tertiary) {
        return cssVar('transparent');
      }
      if (this.tertiaryColorful) {
        return cssVar(`${this.color}-lighten-5`);
      }
      return cssVar(this.color);
    },
    hoverColor(): string {
      if (this.transparent) {
        return cssVar('hover');
      }
      if (this.secondary || this.tertiary) {
        return cssVar(`${this.color}-lighten-5`);
      }
      if (this.tertiaryColorful) {
        return cssVar(`${this.color}-lighten-4`);
      }
      return cssVar(`${this.color}-darken-1`);
    },
    activeColor(): string {
      if (this.transparent) {
        return cssVar('active');
      }
      if (this.secondary || this.tertiary) {
        return cssVar(`${this.color}-lighten-4`);
      }
      if (this.tertiaryColorful) {
        return cssVar(`${this.color}-lighten-3`);
      }
      return cssVar(`${this.color}-darken-2`);
    },
    fontColor(): string {
      if (this.disabled) {
        return this.secondary ? cssVar('grey-lighten-1') : cssVar('grey');
      }
      if (this.secondary || this.tertiary || this.transparent || this.tertiaryColorful) {
        return cssVar(this.color);
      }
      return cssVar('white');
    },
    borderColor(): string {
      if (this.disabled) {
        return this.secondary || this.transparent ? cssVar('grey-lighten-1') : 'transparent';
      }
      if (this.secondary) {
        // return cssVar('grey-lighten-2');
        return cssVar(this.color);
      }
      return 'transparent';
    },
    iconSize(): number {
      if (this.large) {
        return 32;
      }
      if (this.small) {
        return 20;
      }
      if (this.tiny) {
        return 16;
      }
      return 24;
    },
    widthClass(): string {
      if (this.large) {
        return this.icon ? 'tw-w-12' : 'tw-px-5';
      }
      if (this.small) {
        return this.icon ? 'tw-w-8' : 'tw-px-3';
      }
      if (this.tiny) {
        return this.icon ? 'tw-w-6' : 'tw-px-2';
      }
      return this.icon ? 'tw-w-10' : 'tw-px-4';
    },
  },

  mounted() {
    this.isExternalLink = !!(window && !this.href.includes(window.location.hostname) && this.href.startsWith('http'));
  },

  methods: {
    onClick() {
      if (!this.disabled && !this.isLoading) {
        this.$emit('click');
      }
    },
  },
});
</script>

<style lang="postcss">
.loc-button {
  background-color: var(--bg-color);
  color: var(--font-color);
  fill: var(--font-color);
  border-color: var(--border-color);
}
.loc-button:hover {
  background-color: var(--hover-color);
}
.loc-button:active {
  background-color: var(--active-color);
}
</style>
