<template>
  <LocPagination
    v-model="currentPage"
    class="tw-text-sm tw-p-2 sm:tw-p-1 sm:tw-pl-3 xl:tw-p-2 xl:tw-pl-3"
    :items-count="totalItems"
    :page-size.sync="selectedPageSize"
    :page-size-options="pagination.pageSizeOptions"
    :options="options"
    :enabled="pagination.enabled"
    data-test="loc-data-table-footer-pagination"
  />
</template>

<script lang="ts">
import { TablePagination } from '@/modules/@core/models/table-pagination';
import { defineComponent, PropType } from 'vue';
import LocPagination from '@/modules/@core/components/LocPagination/LocPagination.vue';
import { PaginationOptions } from '@/modules/@core/models/pagination-options';

export default defineComponent({
  name: 'LocDataTableFooter',

  components: {
    LocPagination,
  },

  props: {
    pagination: {
      type: Object as PropType<TablePagination>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
  },

  computed: {
    currentPage: {
      get(): number {
        return this.pagination.page;
      },
      set(val: number): void {
        this.$emit('update:pagination', { ...this.pagination, page: val });
      },
    },
    selectedPageSize: {
      get(): number {
        return this.pagination.pageSize;
      },
      set(val: number): void {
        this.$emit('update:pagination', { ...this.pagination, pageSize: val });
      },
    },
    options(): PaginationOptions {
      return {
        ...(this.pagination.options || {}),
      };
    },
  },
});
</script>
