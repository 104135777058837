<template>
  <div id="loc-color-picker" class="tw-flex tw-flex-col">
    <div class="tw-flex tw-justify-between tw-items-end tw-mb-1">
      <span class="tw-text-sm tw-text-grey">
        <slot>
          {{ t('LocColorPicker.select_color') }}
        </slot>
      </span>
      <LocButton tertiary tiny color="secondary" icon="done" @click="$emit('confirm')" />
    </div>

    <div
      class="tw-w-full tw-h-6 tw-px-1 tw-rounded tw-border tw-border-grey-lighten-2 tw-flex tw-items-center"
      :style="{ backgroundColor: hslColor }"
    >
      <LocSlider v-model="hue" :min="0" :max="360" transparent />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocSlider from '@/modules/@core/components/LocSlider/LocSlider.vue';
import LocButton from '@/modules/@core/components/LocButton/LocButton.vue';
import { langService } from '@/modules/translations/const/lang-service';

/** Currently, the color picker only works with setting 'hue' values.
 * If needed, expand this to work as RGB, HEX or HSL color picker.
 */
export default defineComponent({
  name: 'LocColorPicker',

  components: {
    LocSlider,
    LocButton,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
  },

  computed: {
    hue: {
      get(): number {
        return this.value;
      },
      set(val: number) {
        this.$emit('input', val);
      },
    },
    turnBlack(): boolean {
      return this.hue > 355;
    },
    hslColor(): string {
      if (this.turnBlack) {
        return `hsl(${this.hue}, 0%, 40%)`;
      }
      return `hsl(${this.hue}, 60%, 45%)`;
    },
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },
  },
});
</script>
