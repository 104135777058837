<template>
  <div
    class="loc-chip tw-inline-flex tw-items-center tw-leading-none tw-border-solid tw-border tw-relative tw-transition-colors tw-truncate tw-duration-150"
    data-test="loc-chip"
    :class="{
      'tw-pointer-events-none': disabled,
      'tw-h-8': size === 'lg',
      'tw-h-6': size === 'md',
      'tw-h-5 tw-px-two tw-rounded-sm': size === 'sm',
      'tw-rounded tw-px-1': size !== 'sm',
    }"
    :style="{
      '--bg-color': bgColor,
      '--hover-color': hoverColor,
      '--border-color': borderColor,
      '--border-hover-color': borderHoverColor,
      '--icon-color': iconColor,
      '--button-color': buttonColor,
    }"
    @click="$emit('click')"
  >
    <slot name="content">
      <div
        class="tw-truncate"
        :class="{
          'tw-px-1 tw-text-base': size === 'lg',
          'tw-px-two tw-text-sm': size !== 'lg',
        }"
        :style="{
          color: labelColor,
        }"
      >
        <slot>
          {{ label }}
        </slot>
      </div>
    </slot>
    <slot name="buttons" />

    <LocDropdown
      v-if="colorPicker"
      class="color-picker tw-absolute tw-opacity-0 tw-top-0 tw-right-0 tw-flex-shrink-0 tw-h-full tw-flex tw-items-center"
      :disabled="disabled"
      data-test="loc-chip-color-picker"
      :class="{
        'tw-mr-px': size === 'sm' && !clearable,
        'tw-mr-two': size !== 'sm' && !clearable,
        'tw-mr-4 tw-pr-two': size === 'sm' && clearable,
        'tw-mr-5': size !== 'sm' && clearable,
      }"
      @close="$emit('changed-color')"
    >
      <div class="colorize-icon tw-flex tw-p-px tw-rounded-sm tw-transition-colors tw-duration-100 tw-cursor-pointer">
        <LocIcon name="colorize" class="tw-flex-shrink-0" :size="size === 'sm' ? 14 : 16" />
      </div>

      <template #content="{ close }">
        <LocColorPicker v-model="hue" class="tw-px-3 tw-pt-2 tw-pb-3 tw-w-44" @click.native.stop @confirm="close" />
      </template>
    </LocDropdown>

    <div
      v-if="clearable"
      class="icon tw-p-px tw-rounded-sm tw-cursor-pointer tw-flex tw-flex-shrink-0 tw-transition-colors tw-duration-100"
      :class="{
        'tw--mr-px': size === 'sm',
        'tw--mr-two': size !== 'sm',
      }"
      data-test="loc-chip-clear"
      @click.stop.prevent="onClear"
    >
      <LocIcon name="clear" class="tw-flex-shrink-0" :size="size === 'sm' ? 14 : 16" />
    </div>
  </div>
</template>

<script lang="ts">
import { cssVar } from '@/modules/@core/functions/css-var';
import { defineComponent, PropType } from 'vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import LocColorPicker from '@/modules/@core/components/LocColorPicker/LocColorPicker.vue';
import LocDropdown from '@/modules/@core/components/LocDropdown/LocDropdown.vue';
import { colorsService } from '@/modules/@core/services/colors-service';

export default defineComponent({
  name: 'LocChip',

  components: {
    LocIcon,
    LocColorPicker,
    LocDropdown,
  },

  props: {
    color: {
      /** Use predefined colors or valid hue value (0-360) */
      type: String as PropType<
        'primary' | 'secondary' | 'success' | 'grey' | 'accent' | 'warning' | 'error' | `hue:${number}`
      >,
      default: 'primary',
    },
    label: {
      type: String,
      default: 'Content',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String as PropType<'default' | 'darker'>,
      default: 'default',
    },
    /** Adds an X button at the end of the chip  */
    clearable: {
      type: Boolean,
      default: false,
    },
    /** Changes color on hover, should be false when user cannot interact with chip  */
    hoverable: {
      type: Boolean,
      default: true,
    },
    /** Add color picker. Use .sync with @color prop to update it */
    colorPicker: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'md',
    },
  },

  computed: {
    hue: {
      get(): number {
        if (this.isHueDefinedColor) {
          return Number(this.color.split(':')[1]);
        }
        if (typeof document !== 'undefined') {
          const style = getComputedStyle(document.documentElement);
          const hsl = style.getPropertyValue(`--${this.color}`);
          return colorsService.getHSLValues(hsl).h;
        }
        return 0;
      },
      set(hue: number) {
        this.$emit('update:color', `hue:${hue}`);
      },
    },
    isHueDefinedColor(): boolean {
      return this.color.startsWith('hue:');
    },
    isLightHue(): boolean {
      return this.hue > 37 && this.hue < 192;
    },
    turnBlack(): boolean {
      return this.hue > 355;
    },
    bgColor(): string {
      if (this.disabled) {
        return cssVar('grey-lighten-5');
      }
      if (this.isHueDefinedColor) {
        return this.getHslWithLightness(96);
      }
      return cssVar(`${this.color}-lighten-5`);
    },
    hoverColor(): string {
      if (this.hoverable) {
        if (this.isHueDefinedColor) {
          return this.getHslWithLightness(92);
        }
        return cssVar(`${this.color}-lighten-4`);
      }
      return this.bgColor;
    },
    borderColor(): string {
      if (this.disabled) {
        return cssVar('grey-lighten-2');
      }
      if (this.theme === 'darker') {
        if (this.isHueDefinedColor) {
          if (this.isLightHue) {
            return this.getHslWithLightness(60);
          }
          return this.getHslWithLightness(80);
        }
        return cssVar(`${this.color}-lighten-2`);
      }
      if (this.isHueDefinedColor) {
        if (this.isLightHue) {
          return this.getHslWithLightness(83);
        }
        return this.getHslWithLightness(91);
      }
      return cssVar(`${this.color}-lighten-4`);
    },
    borderHoverColor(): string {
      if (this.hoverable) {
        if (this.disabled) {
          return cssVar('grey-lighten-2');
        }
        if (this.theme === 'darker') {
          if (this.isHueDefinedColor) {
            if (this.isLightHue) {
              return this.getHslWithLightness(40);
            }
            return this.getHslWithLightness(50);
          }
          return cssVar(`${this.color}`);
        }
        if (this.isHueDefinedColor) {
          if (this.isLightHue) {
            return this.getHslWithLightness(70);
          }
          return this.getHslWithLightness(85);
        }
        return cssVar(`${this.color}-lighten-3`);
      }
      return this.borderColor;
    },
    buttonColor(): string {
      if (this.disabled) {
        return cssVar('grey-lighten-2');
      }
      if (this.isHueDefinedColor) {
        if (this.isLightHue) {
          return this.getHslWithLightness(73);
        }
        return this.getHslWithLightness(85);
      }
      return cssVar(`${this.color}-lighten-3`);
    },
    iconColor(): string {
      if (this.disabled) {
        return cssVar('grey-lighten-2');
      }
      if (this.isHueDefinedColor) {
        if (this.isLightHue) {
          return this.getHslWithLightness(40);
        }
        return this.getHslWithLightness(50);
      }
      return cssVar(`${this.color}-darken-1`);
    },
    labelColor(): string {
      if (this.disabled) {
        return cssVar('grey');
      }
      return cssVar('secondary-darken-2');
    },
  },

  methods: {
    onClear() {
      if (!this.disabled && this.clearable) {
        this.$emit('clear');
      }
    },

    getHslWithLightness(lightness: number): string {
      if (this.isLightHue) {
        return `hsl(${this.hue}, 65%, ${lightness}%)`;
      }
      if (this.turnBlack) {
        return `hsl(${this.hue}, 0%, ${lightness - 8}%)`;
      }
      return `hsl(${this.hue}, 85%, ${lightness}%)`;
    },
  },
});
</script>

<style lang="postcss" scoped>
.loc-chip {
  background-color: var(--bg-color);
  border-color: var(--border-color);
}
.loc-chip:hover {
  background-color: var(--hover-color);
  border-color: var(--border-hover-color);
}
.loc-chip:hover .color-picker {
  opacity: 1;
}
.icon {
  fill: var(--icon-color);
  fill-opacity: 0.6;
}
.icon:hover {
  background-color: var(--button-color);
  fill-opacity: 1;
}
.colorize-icon {
  background-color: var(--hover-color);
  fill: var(--icon-color);
  fill-opacity: 0.6;
}
.colorize-icon:hover {
  background-color: var(--button-color);
  fill-opacity: 1;
}
</style>
