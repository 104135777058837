<template>
  <LocEmoji :emoji="countryFlag" class="tw-leading-none" />
</template>

<script lang="ts">
import LocEmoji from '@/modules/@core/components/LocEmoji/LocEmoji.vue';
import { mapLanguageToCountry } from '@/modules/@core/const/map-language-to-country';
import countryCodeEmoji from 'country-code-emoji';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocLanguageFlag',

  components: {
    LocEmoji,
  },

  props: {
    code: {
      type: String,
      default: '',
    },
  },

  computed: {
    countryFlag(): string {
      try {
        return countryCodeEmoji(this.normalizedCode);
      } catch {
        return '🗺️';
      }
    },
    normalizedCode(): string {
      const noScriptCode = this.code.split('#')[0];
      const [lang, country] = noScriptCode.split('_');
      if (country) {
        return country;
      }
      const mappedCountry = mapLanguageToCountry[lang];
      return mappedCountry || '';
    },
  },
});
</script>
