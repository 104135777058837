<template>
  <LocTooltip :disabled="tooltip === ''" no-fill-space>
    <div
      class="loc-chip-status tw-inline-flex tw-items-center tw-leading-none tw-transition-colors tw-truncate tw-duration-150"
      :class="{
        'tw-h-8': large,
        'tw-h-6': !large && !small,
        'tw-h-5 tw-px-two tw-rounded-sm': small,
        'tw-rounded tw-px-1': !small,
      }"
      :style="{
        '--bg-color': bgColor,
        '--hover-color': hoverColor,
        color: labelColor,
      }"
      @click="$emit('click', label)"
    >
      <div
        class="tw-truncate tw-no-underline"
        :class="{
          'tw-px-1 tw-text-base': large,
          'tw-px-two tw-text-sm': !large,
        }"
      >
        <slot>
          {{ label }}
        </slot>
      </div>
    </div>
    <template #content>
      <div class="tw-max-w-72">
        {{ tooltip }}
      </div>
    </template>
  </LocTooltip>
</template>

<script lang="ts">
import { cssVar } from '@/modules/@core/functions/css-var';
import { defineComponent } from 'vue';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';

export default defineComponent({
  name: 'LocChipStatus',
  components: { LocTooltip },

  props: {
    color: {
      type: String,
      default: 'primary',
    },
    label: {
      type: String,
      default: 'Content',
    },
    tooltip: {
      type: String,
      default: '',
    },
    darker: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    bgColor(): string {
      if (this.darker) {
        return cssVar(`${this.color}`);
      }
      return cssVar(`${this.color}-lighten-4`);
    },
    hoverColor(): string {
      if (this.tooltip !== '') {
        if (this.darker) {
          return cssVar(`${this.color}-darken-1`);
        }
        return cssVar(`${this.color}-lighten-3`);
      }
      return this.bgColor;
    },
    labelColor(): string {
      if (this.darker) {
        if (this.color === 'warning') {
          return cssVar('secondary-darken-2');
        }
        return cssVar('white');
      }
      if (!this.darker && this.color === 'warning') {
        return cssVar(`${this.color}-darken-3`);
      }
      return cssVar(`${this.color}-darken-2`);
    },
  },
});
</script>

<style lang="postcss" scoped>
.loc-chip-status {
  background-color: var(--bg-color);
}
.loc-chip-status:hover {
  background-color: var(--hover-color);
}
</style>
