<template>
  <div
    class="tw-px-3 xl:tw-px-4 tw-bg-white tw-border tw-border-l-0 tw-border-b-0 tw-border-solid tw-border-grey-lighten-4"
  >
    <LocCheckbox
      :value="allOnPageChecked"
      :intermediate="someOnPageChecked"
      :disabled="disabled"
      type="checkbox"
      class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
      data-test="loc-data-table-select-all-on-page-box"
      @input="(shouldSelectAll) => $emit('input', shouldSelectAll)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocCheckbox from '@/modules/@core/components/LocCheckbox/LocCheckbox.vue';

export default defineComponent({
  name: 'LocDataTableHeaderMultiselect',

  components: {
    LocCheckbox,
  },

  props: {
    allOnPageChecked: {
      type: Boolean,
      required: true,
    },
    someOnPageChecked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
});
</script>
