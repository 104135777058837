<script lang="ts">
import { defineComponent, VNode, PropOptions } from 'vue';
import { Intersect } from '@/modules/vue/const/intersect';
import { getSlot } from '@/modules/@core/functions/get-slot';

export interface IData {
  isActive: boolean;
  hasBeenLoaded: boolean;
}

// noinspection TypeScriptValidateTypes
export default defineComponent({
  name: 'LocLazy',
  directives: { Intersect },

  props: {
    options: {
      type: Object,
      // For more information on types, navigate to:
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      default: () => ({
        root: null, // defaults to browser viewport
        rootMargin: '0px 0px 0px 0px',
        threshold: 0,
      }),
    } as PropOptions<IntersectionObserverInit>,
    tag: {
      type: String,
      default: 'div',
    },
    transition: {
      type: String,
      default: 'loc-fade',
    },
    /** Useful to skip lazyloading of items that are expected to be visible */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      isActive: false,
      hasBeenLoaded: false,
    };
  },

  created() {
    if (this.disabled) {
      this.isActive = true;
      this.hasBeenLoaded = true;
    }
  },

  methods: {
    genContent() {
      const el = getSlot(this.$slots, this.$scopedSlots);
      const children = this.isActive && el;

      if (this.hasBeenLoaded) {
        return children;
      }

      return this.transition
        ? this.$createElement(
            'transition',
            {
              props: { name: this.transition },
            },
            children,
          )
        : children;
    },

    onObserve(entries: IntersectionObserverEntry[], observer: IntersectionObserver, isIntersecting: boolean) {
      if (this.isActive) return;

      this.isActive = isIntersecting;
      if (isIntersecting) {
        this.$emit('loaded');
        this.hasBeenLoaded = true;

        entries.forEach((ref) => {
          if (!ref.isIntersecting) {
            return;
          }

          observer.unobserve(ref.target);
        });
      }
    },
  },

  render(h): VNode {
    return h(
      this.tag,
      {
        attrs: {
          ...this.$attrs,
          'data-test': 'loc-lazy',
        },
        directives: [
          {
            name: 'intersect',
            value: {
              handler: this.onObserve,
              options: this.options,
            },
          },
        ],
        on: this.$listeners,
      },
      [this.genContent()],
    );
  },
});
</script>
