<template>
  <transition v-if="lazyloadEnabled" name="loc-fade">
    <div class="tw-flex tw-border tw-border-l-0 tw-border-t-0 tw-border-solid tw-border-grey-lighten-4">
      <slot v-if="hasBeenObserved" :name="`column-${headerKey}--without-router-link`">
        <LocDataTableCellLink
          :row="row"
          :is-link="isLink"
          :link="link"
          :cell-classes="cellClasses"
          :has-click-event="hasClickEvent"
          :top-aligned="topAligned"
          :tooltip="tooltip"
          @click="$emit('click', $event)"
        >
          <slot :name="`column-${headerKey}`" />
        </LocDataTableCellLink>
      </slot>
    </div>
  </transition>
  <div v-else class="tw-flex tw-border tw-border-l-0 tw-border-t-0 tw-border-solid tw-border-grey-lighten-4">
    <slot :name="`column-${headerKey}--without-router-link`">
      <LocDataTableCellLink
        :row="row"
        :is-link="isLink"
        :link="link"
        :cell-classes="cellClasses"
        :has-click-event="hasClickEvent"
        :top-aligned="topAligned"
        :tooltip="tooltip"
        @click="$emit('click', $event)"
      >
        <slot :name="`column-${headerKey}`" />
      </LocDataTableCellLink>
    </slot>
  </div>
</template>

<script lang="ts">
import { CellTooltip } from '@/modules/@core/models/cell-tooltip';
import { Header } from '@/modules/@core/models/header';
import { Row } from '@/modules/@core/models/row';
import { defineComponent, PropType, PropOptions } from 'vue';
import { EventEmitterSingleton } from '@/modules/@core/services/event-emitter-singleton';
import LocDataTableCellLink from '@/modules/@core/components/LocDataTable/LocDataTableCellLink.vue';

export interface IData {
  hasBeenObserved: boolean;
  unsubscribe: (..._args: any[]) => any;
}

const eventEmitter = EventEmitterSingleton.getInstance();

export default defineComponent({
  name: 'LocDataTableCell',

  components: {
    LocDataTableCellLink,
  },

  props: {
    row: {
      type: Object as PropType<Row>,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    headerKey: {
      type: String,
      required: true,
    },
    isLink: {
      type: Boolean,
      required: true,
    },
    link: {
      required: true,
      validator: (link: undefined | ((..._args: any[]) => any)) => link === undefined || typeof link === 'function',
    } as PropOptions<Header['link']>,
    cellClasses: {
      type: String,
      required: true,
    },
    hasClickEvent: {
      type: Boolean,
      required: true,
    },
    topAligned: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object as PropType<CellTooltip>,
      required: true,
    },
    lazyloadEnabled: {
      type: Boolean,
      required: true,
    },
  },

  data(): IData {
    return {
      hasBeenObserved: false,
      unsubscribe: () => undefined,
    };
  },

  mounted() {
    const eventName = `observed-${this.rowIndex}`;
    // load first five rows to have a "smoother" feeling
    if ([0, 1, 2, 3, 4].includes(this.rowIndex)) {
      this.hasBeenObserved = true;
    } else if (eventEmitter.hasOccurred(eventName)) {
      this.hasBeenObserved = true;
    } else {
      this.unsubscribe = eventEmitter.subscribe(eventName, this.hasBeenObservedResolver).unsubscribe;
    }
  },

  beforeDestroy() {
    this.unsubscribe();
  },

  methods: {
    hasBeenObservedResolver(rowIndex: number) {
      if (this.rowIndex === rowIndex) {
        this.hasBeenObserved = true;
      }
    },
  },
});
</script>
