<template>
  <div :class="item.cssClass">
    <label
      v-if="item.label"
      class="tw-flex tw-items-center tw-justify-between tw-text-sm tw-text-grey tw-leading-none tw-mb-1"
    >
      {{ item.label }}
    </label>

    <div class="tw-grid tw-gap-4 tw-grid-cols-12">
      <template v-for="component in item.components">
        <DynamicFormControl
          v-if="component.visible"
          :key="component.id"
          :item="component"
          class="tw-my-1"
          @update-model="$emit('update-model', $event)"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { FormControlComponent } from '@localazy/markdown-it-compiler';
import DynamicFormControl from '@/modules/@core/components/LocMarkdown/markdown-it-compiler/DynamicFormControl.vue';

export default defineComponent({
  name: 'DynamicFormGroup',

  components: {
    DynamicFormControl,
  },

  props: {
    item: {
      type: Object as PropType<FormControlComponent>,
      required: true,
    },
  },
});
</script>
