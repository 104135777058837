<script lang="ts">
import { defineComponent, VNode } from 'vue';
import twemoji from 'twemoji';

function twitterMoji(emoji: string) {
  const result = twemoji.parse(emoji, {
    folder: 'svg',
    ext: '.svg',
  }) as any;
  return result.replace('twemoji.maxcdn.com/v', 'cdnjs.cloudflare.com/ajax/libs/twemoji');
}

// noinspection TypeScriptValidateTypes
export default defineComponent({
  functional: true,

  render(h, { props, data, listeners }): VNode {
    const classes = [data.class || '', data.staticClass || ''];

    return h('span', {
      class: ['loc-emoji', classes],
      style: data.style,
      attrs: data.attrs,
      on: listeners,
      domProps: {
        innerHTML: twitterMoji(props.emoji),
      },
    });
  },
});
</script>

<style lang="postcss" scoped>
.loc-emoji >>> img.emoji {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}
</style>
