<template>
  <div
    :class="{
      'tw-bg-primary': primary,
      'tw-bg-grey-lighten-3': grey,
      'tw-bg-accent-lighten-4': !grey && !primary,
    }"
    :style="{ height: `${height}px` }"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocDivider',

  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 2,
    },
  },
});
</script>
