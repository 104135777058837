<template>
  <!-- Slider main container -->
  <div class="swiper">
    <!-- Additional required wrapper -->
    <LocCarouselSlides :slide-classes="slideClasses">
      <slot />
    </LocCarouselSlides>
    <!-- If we need pagination -->
    <div v-if="isPaginationEnabled" class="swiper-pagination" />

    <!-- If we need navigation buttons -->
    <div v-if="renderPrevButton" :class="defaultSwiperButtonPrevClass" />
    <div v-if="renderNextButton" :class="defaultSwiperButtonNextClass" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Swiper, { Navigation, Pagination, Autoplay, SwiperOptions, Mousewheel, FreeMode } from 'swiper';
import { merge } from 'lodash-es';
import LocCarouselSlides from '@/modules/@core/components/LocCarousel/LocCarouselSlides.vue';

export default defineComponent({
  components: {
    LocCarouselSlides,
  },

  props: {
    options: {
      type: Object as PropType<SwiperOptions>,
      default: (): SwiperOptions => ({}),
    },
    slideClasses: {
      type: String,
      default: '',
    },
  },

  computed: {
    isPaginationEnabled(): boolean {
      if (this.swiperOptions === undefined) {
        return false;
      }

      if (typeof this.swiperOptions.pagination === 'boolean') {
        return this.swiperOptions.pagination;
      }
      return this.swiperOptions.pagination?.enabled === true;
    },
    swiperOptions(): SwiperOptions {
      const modules: SwiperOptions['modules'] = [];
      if (typeof this.options.pagination === 'boolean' ? this.options.pagination : !!this.options.pagination?.enabled) {
        modules.push(Pagination);
      }
      /**
       * TODO: Add support for custom navigation buttons placement
       * TODO: Fix the caveat of the current implementation if outer placement button has default class name
       *
       * Navigation button can be placed outside of the swiper container
       * Usages of logic to merge:
       * - TestimonialSlider.vue on web
       * - CaseStudiesSlider.vue on web
       *
       * We must check whether the navigation configuration object is boolean or an object
       * If it is an object, and `nextEl` and `prevEl` contain `swiper-button-prev` and `swiper-button-next` classes, render default navigation buttons
       */
      if (typeof this.options.navigation === 'boolean' ? this.options.navigation : !!this.options.navigation?.enabled) {
        modules.push(Navigation);
      }
      if (typeof this.options.autoplay === 'boolean' ? this.options.autoplay : !!this.options.autoplay) {
        modules.push(Autoplay);
      }
      if (typeof this.options.mousewheel === 'boolean' ? this.options.mousewheel : !!this.options.mousewheel) {
        modules.push(Mousewheel);
      }
      if (typeof this.options.freeMode === 'boolean' ? this.options.freeMode : !!this.options.freeMode?.enabled) {
        modules.push(FreeMode);
      }

      const defaultOptions: SwiperOptions = {
        modules,
        speed: 300,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          enabled: false,
        },
        navigation: false,
      };
      return merge(defaultOptions, this.options);
    },
    pushNavigationModule(): boolean {
      return typeof this.options.navigation === 'boolean'
        ? this.options.navigation
        : !!this.options.navigation?.enabled;
    },
    renderPrevButton(): boolean {
      if (typeof this.options.navigation === 'boolean' && this.options.navigation) {
        return true;
      }
      if (
        typeof this.options.navigation === 'object' &&
        this.options.navigation?.prevEl === this.defaultSwiperButtonPrevClass
      ) {
        return true;
      }
      return false;
    },
    renderNextButton(): boolean {
      if (typeof this.options.navigation === 'boolean' && this.options.navigation) {
        return true;
      }
      if (
        typeof this.options.navigation === 'object' &&
        this.options.navigation?.nextEl === this.defaultSwiperButtonNextClass
      ) {
        return true;
      }
      return false;
    },
    defaultSwiperButtonPrevClass(): string {
      return 'swiper-button-prev';
    },
    defaultSwiperButtonNextClass(): string {
      return 'swiper-button-next';
    },
  },

  mounted() {
    const swiper = this.$el as HTMLElement;
    if (swiper) {
      const swiperInstance = new Swiper(swiper, this.swiperOptions);
    }
  },
});
</script>

<style lang="postcss">
/* purgecss start ignore */
@import 'swiper/swiper-bundle.min.css';
/* purgecss end ignore */
</style>
