<script lang="ts">
import { CreateElement, defineComponent, VNode } from 'vue';
import LocButton from '@/modules/@core/components/LocButton/LocButton.vue';

export default defineComponent({
  name: 'LocDisplayMore',

  props: {
    /**
     * Render initially expanded
     */
    expanded: {
      type: Boolean,
      default: false,
    },
    /**
     * Initial render count.
     * If changed after initialization, the visible count is reset
     */
    count: {
      type: Number,
      default: 9,
    },
    /**
     * Fill toggle button
     */
    filled: {
      type: Boolean,
      default: false,
    },
    /**
     * Change toggle from show all/less to incremental expansion
     */
    incrementalExpand: {
      type: Boolean,
      default: false,
    },
    /**
     * Show additional elements until everything is visible
     */
    incrementBy: {
      type: Number,
      default: 20,
    },
  },

  data() {
    return {
      maxVisible: 0,
    };
  },

  computed: {
    shouldLimitCount(): boolean {
      return this.defaultSlotLength() >= this.count;
    },
    allVisible(): boolean {
      return this.defaultSlotLength() <= this.maxVisible;
    },
  },

  watch: {
    count: {
      immediate: true,

      handler(newVal: number) {
        this.maxVisible = newVal;
      },
    },
  },

  created() {
    if (this.expanded) {
      this.maxVisible = this.defaultSlotLength();
    } else {
      this.maxVisible = this.count;
    }
  },

  methods: {
    defaultSlot(): VNode[] {
      return this.$slots.default ? this.$slots.default.filter((slot) => slot.tag !== undefined) : [];
    },

    defaultSlotLength(): number {
      return this.defaultSlot().length;
    },

    createToggleButton(createElement: CreateElement) {
      let label = '';
      if (this.allVisible) {
        label = 'Show less';
      } else if (this.incrementalExpand) {
        label = 'Show more';
      } else {
        label = 'Show all';
      }

      const button = createElement(
        LocButton,
        {
          props: {
            small: !this.filled,
            secondary: !this.filled,
          },
          on: {
            click: this.handleButtonClick,
          },
          attrs: {
            'data-test': 'loc-display-more-button',
          },
        },
        label,
      );

      return button;
    },

    handleButtonClick() {
      if (this.incrementalExpand) {
        this.handleIncrementalButtonClick();
      } else {
        this.handleToggleButtonClick();
      }
    },

    handleIncrementalButtonClick() {
      if (this.allVisible) {
        this.maxVisible = this.count;
      } else {
        this.maxVisible += this.incrementBy;
      }
    },

    handleToggleButtonClick() {
      if (this.allVisible) {
        this.maxVisible = this.count;
      } else {
        this.maxVisible = this.defaultSlotLength();
      }
    },
  },

  render(createElement): VNode {
    let content = this.defaultSlot();
    if (this.shouldLimitCount) {
      content = content.slice(0, this.maxVisible);
    }

    const button = this.createToggleButton(createElement);
    const shouldRenderButton = this.defaultSlotLength() > this.count;
    return createElement('div', [
      createElement(
        'div',
        {
          attrs: {
            'data-test': 'loc-display-more-elements',
          },
        },
        content,
      ),
      createElement(
        'div',
        {
          class: {
            'tw-text-center tw-mt-4': true,
          },
        },
        shouldRenderButton ? [button] : [],
      ),
    ]);
  },
});
</script>
