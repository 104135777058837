<template>
  <div>
    <label
      v-if="label"
      :for="name"
      data-test="loc-input-label"
      class="tw-flex tw-justify-between tw-text-sm tw-text-grey tw-leading-none tw-mb-1"
    >
      <slot name="label" :label="label">{{ label }}</slot>
      <slot name="label-after">
        <span v-if="required && showRequiredLabel">*</span>
        <span v-if="!required && showOptionalLabel">
          {{ t('LocInput.optional') }}
        </span>
        <template v-if="labelHelp">
          <LocTooltip placement="top" secondary>
            <LocIcon
              name="info"
              :size="16"
              class="tw-fill-secondary hover:tw-fill-primary"
              :style="{ cursor: 'help' }"
            />

            <template #content>
              <span
                :class="{
                  'tw-whitespace-pre-line': labelHelp.includes('\n'),
                }"
              >
                {{ labelHelp }}
              </span>
            </template>
          </LocTooltip>
        </template>
      </slot>
    </label>
    <div
      class="tw-flex tw-transition-colors tw-duration-75 tw-border-solid tw-overflow-hidden tw-items-center"
      data-test="loc-input-wrapper"
      :class="{
        'tw-h-10 tw-py-2 tw-text-base': !large && !small,
        'tw-h-12 tw-py-3 tw-text-xl': large,
        'tw-h-8 tw-py-1 tw-text-sm': small && !large,
        'tw-border-grey-lighten-2': !isFocused,
        'tw-border-primary': isFocused,
        'tw-border-warning': !!warning && !error,
        'tw-border-error': !!error,
        'tw-border-0 tw-border-b tw-px-0': simple,
        'tw-border tw-rounded tw-px-3': !simple,
        'tw-bg-grey-lighten-5 tw-pointer-events-none': disabled,
        'tw-bg-white': !disabled,
      }"
      :style="[noBorder ? { border: 'none' } : {}]"
      @click="focus"
    >
      <slot name="input-before" />
      <div class="tw-relative tw-flex tw-items-center tw-flex-grow tw-leading-snug tw-w-full">
        <input
          :id="id"
          ref="input"
          :value="value"
          data-test="loc-input-field"
          class="focus:tw-outline-none tw-placeholder-grey-lighten-1 tw-h-full tw-w-full"
          :type="type"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          :required="required"
          :name="name"
          :class="[allInputClasses]"
          :readonly="readonly"
          :disabled="disabled"
          :tabindex="tabindex"
          @focus="onFocus"
          @blur="onBlur"
          @input="onInput"
          @keyup="(e) => $emit('keyup', e)"
          @keyup.enter="$emit('enter')"
          @keyup.escape="$emit('escape')"
          @change="onChange"
        />
        <div
          v-if="isSlotSet('input-value-after') && !isFocused"
          class="tw-absolute tw-top-0"
          :style="{
            left: inputValueAfterOffset,
          }"
        >
          <span
            ref="inputValueAfterHiddenValue"
            class="tw-absolute tw-top-0 tw-left-0 tw-whitespace-nowrap tw-invisible"
          >
            {{ value }}
          </span>
          <slot name="input-value-after" />
        </div>
      </div>
      <slot name="input-after">
        <div
          class="tw-flex-shrink tw-flex tw-items-center tw-h-full"
          data-test="loc-input-right"
          :class="{
            'tw-fill-primary': isFocused,
            'tw-fill-secondary': !isFocused && !disabled,
            'tw-fill-grey': disabled,
          }"
        >
          <slot name="icon-right" />
        </div>
      </slot>
    </div>
    <div
      v-if="!noDetails"
      data-test="loc-input-details"
      class="tw-h-5 tw-mt-1 tw-leading-tight tw-text-sm tw-flex tw-justify-between"
    >
      <span v-if="error" class="tw-text-error tw-flex tw-items-center">
        <LocIcon name="error-circle" class="tw-w-4 tw-h-4 tw-fill-error tw-mr-1" />
        <span data-test="loc-input-error">{{ error }}</span>
      </span>
      <span
        v-if="counter > 0"
        class="tw-ml-auto"
        :class="{ 'tw-text-error': inputLength > counter, 'tw-text-grey': inputLength <= counter }"
      >
        {{ inputLength }}/{{ counter }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocTooltip from '@/modules/@core/components/LocTooltip/LocTooltip.vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import { langService } from '@/modules/translations/const/lang-service';

export interface IData {
  isFocused: boolean;
  isTyping: boolean;
  debounceFn: ReturnType<typeof setTimeout> | undefined;
  inputContentWidth: number;
  observer: IntersectionObserver | null;
}

export default defineComponent({
  name: 'LocInput',

  components: {
    LocTooltip,
    LocIcon,
  },

  props: {
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    simple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    required: {
      type: Boolean,
      default: false,
    },
    showOptionalLabel: {
      type: Boolean,
      default: false,
    },
    showRequiredLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Input version without label & messages.
     * Reduces component height to the input box itself.
     */
    noDetails: {
      type: Boolean,
      default: false,
    },
    /**
     * Error message to be shown under the input box.
     */
    error: {
      type: String,
      default: '',
    },
    warning: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelHelp: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    inputClasses: {
      type: String,
      default: '',
    },
    counter: {
      type: Number,
      default: 0,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    /**
     * Optional debounce functionality,
     * emits input event after specified inactivity in ms
     */
    debounce: {
      type: Number,
      default: 0,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },

  data(): IData {
    return {
      isFocused: false,
      isTyping: false,
      debounceFn: undefined,
      inputContentWidth: 0,
      observer: null,
    };
  },

  computed: {
    allInputClasses(): string {
      let classes = this.inputClasses;
      if (this.disabled) {
        classes += ' tw-bg-grey-lighten-5 tw-text-grey tw-pointer-events-none';
      }
      return classes;
    },
    inputLength(): number {
      if (typeof this.value === 'string') {
        return this.value.length || 0;
      }
      return 0;
    },
    inputValueAfterOffset(): string {
      return `${this.inputContentWidth}px`;
    },
  },

  watch: {
    value: {
      async handler() {
        this.setInputContentWidth().then().catch();
      },
    },

    isFocused: {
      async handler() {
        this.setInputContentWidthInitHandler();
      },
    },
  },

  async created() {
    await this.setInputContentWidthInitHandler();
  },

  async mounted() {
    if (this.autofocus) {
      await this.$nextTick(); // Wait for previous Vue events to occur
      this.focus();

      // If element should not be focused on mount and the `input-value-after` slot is set, init the observer
    } else if (this.isSlotSet('input-value-after')) {
      const el = this.$refs.inputValueAfterHiddenValue as HTMLElement;
      if (el) {
        this.initVisibilityObserver(el, (observer: IntersectionObserver, isIntersecting: boolean) => {
          if (isIntersecting) {
            this.setInputContentWidth();
            // ! Stop observing
            observer.unobserve(el);
          }
        });
      }
    }
  },

  beforeDestroy() {
    if (this.debounceFn) {
      clearTimeout(this.debounceFn);
    }
    this.observer?.disconnect();
    this.observer = null;
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },

    async setInputContentWidthInitHandler() {
      if (!this.isFocused) {
        await this.setInputContentWidth();
      }
    },

    async setInputContentWidth() {
      await this.$nextTick();
      let retries = 20;
      const second = 1000;
      const interval = 20;
      const clear = setInterval(() => {
        const el = this.$refs.inputValueAfterHiddenValue as HTMLElement;
        if (retries === 0) {
          clearInterval(clear);
        }
        if (el) {
          this.inputContentWidth = el.offsetWidth;
          clearInterval(clear);
        }
        retries -= 1;
      }, second / interval);
    },

    focus() {
      (this.$refs.input as HTMLInputElement).focus();
      this.onFocus();
    },

    blur() {
      (this.$refs.input as HTMLInputElement).blur();
      this.onBlur();
    },

    onFocus() {
      this.isFocused = true;
      this.$emit('focus');
    },

    async onBlur() {
      this.isFocused = false;
      this.$emit('blur');
    },

    onChange(e: Event) {
      this.$emit('change', (e.target as HTMLInputElement).value);
    },

    onInput(e: any) {
      if (this.debounce > 0) {
        this.isTyping = true;
        if (this.debounceFn) {
          clearTimeout(this.debounceFn);
        }
        this.debounceFn = setTimeout(() => {
          this.isTyping = false;
          this.$emit('input', e.target.value);
        }, this.debounce);
      } else {
        this.$emit('input', e.target.value);
      }
    },

    isSlotSet(slotName: string) {
      return this.$slots[slotName] !== undefined;
    },

    /**
     * The `input-value-after` calculation cannot be done on hidden elements.
     * Therefore we use an IntersectionObserver to detect when the element is visible.
     */
    initVisibilityObserver(
      element: HTMLElement,
      callback: (observer: IntersectionObserver, isObserving: boolean) => void,
    ) {
      const options = {
        root: document.documentElement,
      };

      this.observer = Object.freeze(
        new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            const isIntersecting = entry.intersectionRatio > 0;
            if (this.observer) {
              callback(this.observer, isIntersecting);
            }
          });
        }, options),
      );

      this.observer.observe(element);
    },
  },
});
</script>
