<template>
  <div
    class="tw-absolute tw-top-0 tw-left-0 tw-items-center tw-justify-center tw-flex-col"
    :class="{
      'hovered-avatar tw-flex': hovered,
      'tw-bg-none tw-hidden': !hovered,
    }"
    :style="{
      width: dimension,
      height: dimension,
    }"
  >
    <LocIcon class="tw-fill-white" name="upload" :size="iconSize" />
    <div
      v-if="shouldShowDimensions"
      data-test="dimensions-text-wrapper"
      class="tw-w-full tw-items-center tw-justify-center tw-flex tw-flex-col tw-text-xs tw-font-medium tw-leading-tight tw-my-1"
    >
      <div>{{ t('LocAvatarOverlay.min-72x72') }}</div>
      <div class="tw-border-b tw-border-primary-lighten-1 tw-text-center tw-pb-1">
        {{ t('LocAvatarOverlay.max-3072x3072') }}
      </div>
      <div class="tw-pt-1">
        {{ t('LocAvatarOverlay.max-5-mb') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';
import { langService } from '@/modules/translations/const/lang-service';

export default defineComponent({
  components: {
    LocIcon,
  },

  props: {
    hovered: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      required: true,
    },
  },

  computed: {
    iconSize(): number {
      return this.size * 0.25;
    },
    shouldShowDimensions(): boolean {
      return this.hovered && this.size > 120;
    },
    dimension(): string {
      return `${this.size}px`;
    },
  },

  methods: {
    t(key: string) {
      return langService.t(key);
    },
  },
});
</script>

<style lang="postcss" scoped>
.hovered-avatar {
  background: linear-gradient(0deg, rgba(6, 111, 239, 0.8), rgba(6, 111, 239, 0.8));
}
</style>
