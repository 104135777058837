<template>
  <div class="tw-w-fulls">
    <div class="tw-relative tw-overflow-hidden tw-w-full" :style="containerStyles">
      <div
        class="tw-background-contain tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full"
        :style="{
          backgroundImage: `url(${imageTwo})`,
          ...imgStyles,
        }"
      />
      <div
        class="tw-background-contain tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full"
        :style="{
          width: `${sliderPosition}%`,
          backgroundImage: `url(${imageOne})`,
          ...imgStyles,
        }"
      />
      <input
        v-model="sliderPosition"
        type="range"
        min="0"
        max="100"
        class="slider tw-absolute tw-appearance-none tw-w-full tw-h-full tw-transition-all tw-duration-100"
        name="slider"
        @input="onInput"
        @change="onInput"
      />
      <slot v-if="!hideSliderLine" name="slider-line">
        <div class="tw-bg-white tw-pointer-events-none tw-absolute tw-w-1" :style="sliderLineStyles" />
      </slot>
      <div v-if="!useBrowserSlideButton" class="tw-absolute tw-pointer-events-none" :style="sliderButtonStyles">
        <slot name="slider-button">
          <div class="tw-min-w-11 tw-min-h-11 tw-bg-white tw-rounded-full tw-flex tw-items-center tw-justify-center">
            <slot name="slider-button-content">
              <LocIcon name="chevron-left" :size="20" class="tw-fill-secondary-darken-4 tw-flex-shrink-0" />
              <LocIcon name="chevron-right" :size="20" class="tw-fill-secondary-darken-4 tw-flex-shrink-0" />
            </slot>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { debounce } from 'lodash-es';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

const DEFAULT_SLIDER_POSITION = 50;
const SLIDER_LINE_WIDTH_PX = 4;
const SLIDER_BUTTON_DIAMETER_PX = 44;

export interface IData {
  sliderPosition: number;
  imageWidth: number;
  imageHeight: number;
  containerWidth: number;
  containerHeight: number;
}

export default defineComponent({
  name: 'LocBeforeAfterImageSlider',

  components: {
    LocIcon,
  },

  props: {
    /**
     * Fact: Assuming that the imageOne is the same size as the imageTwo
     */
    imageOne: {
      type: String,
      required: true,
    },
    imageTwo: {
      type: String,
      default: '',
    },
    initSliderPosition: {
      type: Number,
      default: DEFAULT_SLIDER_POSITION,
    },
    useBrowserSlideButton: {
      type: Boolean,
      default: false,
    },
    hideSliderLine: {
      type: Boolean,
      default: false,
    },
    /** adjusts to original image size */
    autoImageDimensions: {
      type: Boolean,
      default: false,
    },
    /** Uses parents height to calculate sizes. Parent must be rendered when initiating */
    adjustToParentDimensions: {
      type: Boolean,
      default: false,
    },
  },

  data(): IData {
    return {
      sliderPosition: this.initSliderPosition,
      imageWidth: 0,
      imageHeight: 0,
      containerWidth: 0,
      containerHeight: 0,
    };
  },

  computed: {
    renderRatio(): number {
      return this.containerWidth / this.imageWidth;
    },
    containerStyles() {
      return {
        width: `${this.containerWidth}px`,
        height: `${this.containerHeight}px`,
      };
    },
    imgStyles() {
      return {
        backgroundSize: `${this.containerWidth}px 100%`,
      };
    },
    sliderLineStyles() {
      return {
        left: `calc(${this.sliderPosition}% - ${SLIDER_LINE_WIDTH_PX / 2}px)`,
        height: '100%',
      };
    },
    sliderButtonStyles() {
      return {
        top: `calc(50% - ${SLIDER_BUTTON_DIAMETER_PX / 2}px)`,
        left: `calc(${this.sliderPosition}% - ${SLIDER_BUTTON_DIAMETER_PX / 2}px)`,
      };
    },
  },

  mounted() {
    this.getImageDimensions();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.containerDimensionsListener());
    }
  },

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.containerDimensionsListener());
    }
  },

  methods: {
    onInput() {
      this.$emit('input', this.sliderPosition);
    },

    getImageDimensions() {
      const img = new Image();
      img.onload = () => {
        this.imageWidth = img.naturalWidth;
        this.imageHeight = img.naturalHeight;
        this.setContainerDimensions();
      };
      img.src = this.imageOne;
    },

    setContainerDimensions() {
      if (this.adjustToParentDimensions) {
        this.containerWidth = ((this.$el as HTMLElement).parentElement as HTMLElement).offsetWidth;
      } else if (this.autoImageDimensions) {
        this.containerWidth = this.imageWidth;
      } else {
        this.containerWidth = (this.$el as HTMLElement).offsetWidth;
      }

      if (this.adjustToParentDimensions || this.autoImageDimensions) {
        this.containerHeight = this.imageHeight * this.renderRatio;
      } else {
        this.containerHeight = (this.$el as HTMLElement).offsetHeight;
      }
    },

    containerDimensionsListener() {
      return debounce(() => {
        this.setContainerDimensions();
      }, 200);
    },
  },
});
</script>

<style lang="postcss" scoped>
.slider {
  -webkit-appearance: none;
  background-color: rgba(242, 242, 242, 0.15);
}

.slider:hover {
  background-color: transparent;
}
</style>
