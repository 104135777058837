<template>
  <div
    class="tw-block tw-border-2 tw-border-primary-lighten-4 tw-bg-accent-lighten-5 tw-rounded-md tw-px-6 tw-py-4 tw-mt-2 tw-mb-4"
    :class="`form-${id}`"
  >
    <div v-if="formPresets && formPresets.length > 0" class="tw-mb-4">
      <LocButton
        v-for="(preset, index) in formPresets"
        :key="index"
        small
        primary
        class="tw-mr-2"
        @click="$emit('apply-preset', { formId: id, index })"
      >
        {{ preset }}
      </LocButton>
    </div>
    <div class="tw-grid tw-gap-4 tw-grid-cols-12">
      <template v-for="item in formComponents">
        <template v-if="item.isGroup">
          <DynamicFormGroup :key="item.id" :item="item" @update-model="$emit('update-model', $event)" />
        </template>
        <template v-else>
          <DynamicFormControl
            v-if="item.visible"
            :key="item.id"
            :item="item"
            @update-model="$emit('update-model', $event)"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { FormControlComponent, IFormBuilder } from '@localazy/markdown-it-compiler';
import LocButton from '@/modules/@core/components/LocButton/LocButton.vue';
import DynamicFormControl from '@/modules/@core/components/LocMarkdown/markdown-it-compiler/DynamicFormControl.vue';
import DynamicFormGroup from '@/modules/@core/components/LocMarkdown/markdown-it-compiler/DynamicFormGroup.vue';

export default defineComponent({
  name: 'DynamicForm',

  components: {
    LocButton,
    DynamicFormControl,
    DynamicFormGroup,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    formBuilder: {
      type: Object as PropType<IFormBuilder>,
      required: true,
    },
  },

  computed: {
    formPresets(): string[] {
      return this.formBuilder?.getPresets(this.id) || [];
    },
    formComponents(): FormControlComponent[] {
      return this.formBuilder.getComponents(this.id);
    },
  },
});
</script>
