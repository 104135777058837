<template>
  <div
    class="tw-bg-white tw-flex tw-text-grey tw-select-none tw-border tw-border-l-0 tw-border-b-0 tw-border-solid tw-items-stretch tw-border-grey-lighten-4 tw-text-sm tw-h-12 xl:tw-h-14"
    @mouseenter="isHoveredOver = true"
    @mouseleave="isHoveredOver = false"
  >
    <div
      data-test="loc-data-table-header-cell"
      class="tw-flex-1 tw-flex tw-justify-between tw-items-center"
      @click="sortable && onSort(headerKey)"
      @mouseenter="$emit('mouseenter')"
      @mouseleave="$emit('mouseleave')"
    >
      <slot :name="`header-${headerKey}`">
        {{ label !== undefined ? label : headerKey }}
      </slot>

      <slot :name="`header-${headerKey}-sort-icon`">
        <div v-if="sortable" class="tw-flex">
          <LocIcon :name="sortIconName" :class="sortIconClass" :size="14" dir="right" />
        </div>
      </slot>
    </div>
    <slot :name="`header-${headerKey}-right`" />
  </div>
</template>

<script lang="ts">
import { sortIconClass } from '@/modules/@core/functions/data-table/sort-icon-class';
import { sortIconName } from '@/modules/@core/functions/data-table/sort-icon-name';
import { Sort } from '@/modules/@core/models/sort';
import { SortOrder } from '@/modules/@core/enums/sort-order';
import { defineComponent, PropType } from 'vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export interface IData {
  isHoveredOver: boolean;
}

export default defineComponent({
  name: 'LocDataTableHeaderColumn',

  components: {
    LocIcon,
  },

  props: {
    headerKey: {
      type: [String, Number],
      required: true,
    },
    sortable: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    sort: {
      type: Object as PropType<Sort>,
      required: true,
    },
    setQuery: {
      type: Boolean,
      default: true,
    },
  },

  data(): IData {
    return {
      isHoveredOver: false,
    };
  },

  computed: {
    sortIconClass(): string {
      return sortIconClass(
        this.headerKey.toString(),
        this.localSort.sortBy,
        this.localSort.sortOrder,
        this.isHoveredOver ? this.headerKey.toString() : '',
      );
    },
    sortIconName(): string {
      return sortIconName(this.headerKey.toString(), this.localSort.sortBy, this.localSort.sortOrder);
    },
    localSort: {
      get(): Sort {
        return this.sort;
      },
      set(sort: Sort) {
        this.$emit('update:sort', sort);
        this.setQueryParams(sort);
      },
    },
  },

  created() {
    this.resolvePageQuery();
  },

  methods: {
    onSort(key: string | number) {
      const updatedSort: Sort = { sortBy: this.localSort.sortBy, sortOrder: this.localSort.sortOrder };
      if (key !== updatedSort.sortBy) {
        updatedSort.sortBy = key.toString();
        updatedSort.sortOrder = SortOrder.ASC;
        this.localSort = updatedSort;
        return;
      }
      switch (updatedSort.sortOrder) {
        case SortOrder.ASC:
          updatedSort.sortOrder = SortOrder.DESC;
          break;
        case SortOrder.DESC:
          updatedSort.sortOrder = SortOrder.UNSORTED;
          break;
        default:
          updatedSort.sortOrder = SortOrder.ASC;
      }
      this.localSort = updatedSort;
    },

    setQueryParams(sort: Sort) {
      if (this.$router && this.setQuery) {
        const routeParams = {
          query: {
            ...this.$route.query,
            sort: sort.sortOrder.toString(),
            sortBy: sort.sortBy === null ? undefined : sort.sortBy,
          },
          hash: this.$route.hash,
          params: this.$route.params,
          path: this.$route.path,
        };

        const resolvedUrl = this.$router.resolve(routeParams);
        if (resolvedUrl.href !== this.$route.fullPath) {
          this.$router.replace(routeParams).catch(() => {});
        }
      }
    },

    resolvePageQuery() {
      if (this.$route && this.setQuery) {
        const currentSort = this.$route?.query?.sort;
        const currentSortBy = this.$route?.query?.sortBy;
        const updatedSort = { sortBy: this.sort.sortBy, sortOrder: this.sort.sortOrder };
        let shouldUpdate = false;
        if (typeof currentSort === 'string') {
          updatedSort.sortOrder = +currentSort as SortOrder;
          shouldUpdate = true;
        }
        if (typeof currentSortBy === 'string') {
          updatedSort.sortBy = currentSortBy;
          shouldUpdate = true;
        }
        if (shouldUpdate) {
          this.localSort = updatedSort;
        }
      }
    },
  },
});
</script>
