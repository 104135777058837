<template>
  <!-- @dragover.prevent enables to receive the drop event -->
  <form
    enctype="multipart/form-data"
    class="tw-relative tw-transition-colors tw-duration-100 tw-ease-out"
    @dragenter.prevent="localIsDraggedOver = true"
    @dragover.prevent
    @dragleave="onDragLeave"
    @drop.prevent="onItemsDropped"
  >
    <!-- @slot Default slot that allows full custom styling -->
    <slot>
      <div class="subtitle tw-px-6 tw-text-primary">
        <!-- @slot Encouragement text to upload a file -->
        <slot name="upload-files-label" />

        <slot name="upload-files-icon">
          <LocIcon class="tw-w-24 tw-h-24 tw-fill-primary tw-mx-auto" name="drag-drop-line" />
        </slot>
      </div>
      <!-- @slot Optional content in the dropbox area below at it's bottom -->
      <slot name="upload-dropbox-bottom" />
    </slot>
    <input type="file" :multiple="multiple" :accept="accept" class="input-file" @change="onFileAdd($event)" />
  </form>
</template>

<script lang="ts">
// REFERENCE: https://scotch.io/tutorials/how-to-handle-file-uploads-in-vue-2
import { defineComponent, PropType } from 'vue';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export interface IData {
  isDraggedOver: boolean;
  localValue: File[];
}

export default defineComponent({
  name: 'FileUpload',

  components: {
    LocIcon,
  },

  props: {
    value: {
      type: Array as PropType<File[]>,
      default: () => [],
    },
    /**
     * Accepted file types
     */
    accept: {
      type: String,
      default: 'application/*',
    },
    /**
     * Accept multiple files
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    /**
     * Maximum file size in bytes
     * Set to negative to allow any size
     */
    maxFileSize: {
      type: Number,
      default: -1,
    },
    /**
     * When true, staged files are not persisted.
     * You may upload the same file twice (it's offered in the native upload modal)
     * Is overriden when value prop is provided
     */
    stateless: {
      type: Boolean,
      default: false,
    },
    nonFilesItemsDroppedCallback: {
      type: Function,
      default: undefined,
    },
  },

  data(): IData {
    return {
      isDraggedOver: false,
      localValue: [],
    };
  },

  computed: {
    files: {
      get(): File[] {
        return isPropSet(this.$options) ? this.value : this.localValue;
      },
      set(files: File[]): void {
        this.$emit('input', files);
        if (!this.stateless) {
          this.localValue = files;
        }
      },
    },
    localIsDraggedOver: {
      get(): boolean {
        return this.isDraggedOver;
      },
      set(isDraggedOver: boolean): void {
        this.isDraggedOver = isDraggedOver;
        this.$emit('dragged-over-changed', isDraggedOver);
      },
    },
  },

  methods: {
    stageFiles(files: FileList | File[]) {
      if (this.maxFileSize >= 0) {
        const invalidFiles = Array.from(files).filter((file) => file.size > this.maxFileSize);
        invalidFiles.forEach((file) => {
          this.$emit('file-too-large', file);
        });

        if (invalidFiles.length > 0) {
          this.$emit('files-too-large', invalidFiles);
          return;
        }
      }

      if (this.multiple) {
        this.files = [...this.files, ...Array.from(files)];
      } else {
        this.files = Array.from(files);
      }
      this.$emit('change', files);
    },

    onFileAdd(event: Event) {
      const { files } = (event.target as HTMLInputElement) || {};

      if (files === null) {
        return;
      }
      this.stageFiles(files);
    },

    onDragLeave(event: DragEvent) {
      // firing the event on .input-file causes glitchy behaviour
      if (event.relatedTarget === this.$el.querySelector('.input-file')) {
        return;
      }
      this.localIsDraggedOver = false;
    },

    onItemsDropped(event: DragEvent) {
      this.localIsDraggedOver = false;

      if (event.dataTransfer?.files.length) {
        this.stageFiles(event.dataTransfer.files);
        return;
      }
      // if no files were dropped, call the non-files dropped callback if provided
      if (this.nonFilesItemsDroppedCallback) {
        this.nonFilesItemsDroppedCallback(event);
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
.input-file {
  position: absolute;
  width: 200%;
  margin-left: -100%; /* Move original input outside so it does not obscure cursor styling */
  top: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0; /* invisible but it's there! */
}
::-webkit-file-upload-button {
  @apply tw-cursor-pointer;
}
</style>
