<template>
  <div
    data-test="loc-showcase-item"
    class="tw-border-solid tw-flex tw-items-center tw-cursor-pointer tw-relative"
    :class="{
      'tw-border-primary tw-text-secondary-darken-2': isActive,
      'tw-border-primary-lighten-4 tw-text-secondary-lighten-1': !isActive,
      'tw-border-b-2 tw-w-full': horizontal,
      'tw-border-l-2 tw-pl-4 lg:tw-pl-5 tw-pt-3 tw-pb-4': !horizontal,
    }"
    @click="onItemClick"
  >
    <!--
    TODO: This div should be visible when autoplaz is running (-> not-shown after click on item)
          Animation length should be same as a slideInterval prop in LocShowcase
    <div
      v-if="isActive"
      class="tw-absolute tw-bottom-0 tw-bg-primary-lighten-3"
      :class="{
        'tw-right-0 tw-h-two animate-width tw--mb-two': horizontal,
        'tw-left-0 tw-w-two animate-height tw--ml-two': !horizontal,
      }"
    />
    -->
    <div
      v-if="numberedItems"
      data-test="list-showcase-item-numbered"
      class="tw-mr-4 lg:tw-mr-5 tw-text-lg lg:tw-text-xl tw-font-medium"
      :class="{
        'tw-text-primary': isActive,
        'tw-text-primary-lighten-3': !isActive,
      }"
    >
      {{ index > 10 ? index + 1 : `0${index + 1}` }}
    </div>
    <LocIcon
      v-else-if="item.icon"
      :name="item.icon"
      data-test="list-showcase-item-icon"
      class="tw-mr-4 lg:tw-mr-5"
      :class="{
        'tw-fill-primary': isActive,
        'tw-fill-primary-lighten-3': !isActive,
        'tw-h-8 tw-w-8 lg:tw-h-10 lg:tw-w-10': !small,
        'tw-h-6 tw-w-6 lg:tw-h-8 lg:tw-w-8': small,
      }"
    />
    <div v-if="!horizontal" class="tw-flex tw-flex-col tw-leading-tight tw-text-base">
      <span class="tw-font-medium tw-text-lg lg:tw-text-xl tw-my-1">
        {{ item.title }}
      </span>
      <span v-if="item.subtitle">
        {{ item.subtitle }}
      </span>
      <div
        class="lg:tw-text-lg tw-transition-all"
        :class="{
          'tw-opacity-100 tw-h-auto tw-duration-700': isActive,
          'tw-opacity-0 tw-h-0 tw-pointer-events-none': !isActive,
        }"
      >
        <slot name="description" />
      </div>
    </div>
    <div v-else class="tw-text-base tw-uppercase tw-font-medium tw-tracking-wide">
      {{ item.title }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ShowcaseItem } from '@/modules/@core/models/showcase-item';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export default defineComponent({
  name: 'ListShowcaseItem',

  components: {
    LocIcon,
  },

  props: {
    item: {
      type: Object as PropType<ShowcaseItem>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    numberedItems: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onItemClick() {
      this.$emit('click', this.index);
    },
  },
});
</script>

<style lang="postcss" scoped>
.animate-width {
  animation: anim-width linear 4000ms infinite;
}

.animate-height {
  animation: anim-height linear 4000ms infinite;
}

@keyframes anim-width {
  0% {
    width: 100%;
  }
  100% {
    width: 0px;
  }
}

@keyframes anim-height {
  0% {
    height: 100%;
  }
  100% {
    height: 0px;
  }
}
</style>
