<template>
  <div
    class="tw-flex"
    :class="{
      'tw-opacity-50': disabled,
      'tw-items-center': centered,
      'tw-items-start': !centered,
    }"
  >
    <div
      class="tw-w-4 tw-min-w-4 tw-h-4 tw-relative tw-border tw-rounded-sm tw-border-solid tw-overflow-hidden tw-flex-shrink-0"
      :class="{
        'tw-border-grey': !computedValue && !intermediate,
        'tw-border-primary tw-bg-primary': computedValue || intermediate,
      }"
    >
      <input
        :id="id"
        :value="computedValue"
        class="tw-z-10 tw-absolute tw-opacity-0 tw-w-full tw-h-full tw-top-0 tw-left-0 tw-text-white"
        type="checkbox"
        :disabled="disabled"
        :class="{
          'tw-bg-primary': computedValue || intermediate,
          'tw-bg-grey': disabled,
          'tw-cursor-pointer': !disabled,
        }"
        @input="onInput"
      />
      <span
        class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-text-white"
        :class="{
          'tw-bg-primary ': computedValue || intermediate,
          'tw-bg-grey-lighten-4': disabled && !computedValue && !intermediate,
        }"
      >
        <slot v-if="computedValue" name="checked">
          <LocIcon name="check-bold" class="tw-fill-white" :size="16" />
        </slot>
        <slot v-else-if="intermediate" name="intermediate">
          <LocIcon name="minus-bold" class="tw-fill-white" :size="16" />
        </slot>
        <slot v-else name="unchecked" />
      </span>
    </div>
    <label
      v-if="hasDefaultSlot"
      class="tw-ml-2 tw-leading-none tw-text-secondary-darken-2"
      :for="id"
      :class="[
        labelClasses,
        {
          'tw-cursor-pointer': !disabled,
          'tw--mt-px': !centered,
        },
      ]"
      @click="onInput"
    >
      <slot />
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { isPropSet } from '@/modules/@core/functions/utils/is-prop-set';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export default defineComponent({
  name: 'LocCheckbox',

  components: {
    LocIcon,
  },

  props: {
    /**
     * A DOMString representing the id of the checkbox
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * Control whether the checkbox should be checked from outside of the component.
     */
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * The checkbox's value is neither true nor false, but is instead indeterminate,
     * meaning that its state cannot be determined or stated in pure binary terms
     */
    intermediate: {
      type: Boolean,
      default: false,
    },
    /**
     * Disabled checkbox state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Set to false for multiline labels, to align the text at the top with the checkbox.
     *
     * TODO:This would be ideal to have on false in the future as a default, but due to time consumed on testing,
     * we will start with it as a triggered improvement.
     */
    centered: {
      type: Boolean,
      default: true,
    },
    /**
     * Customizable classes of the checkbox label
     */
    labelClasses: {
      type: String,
      default: 'tw-leading-tight tw-text-secondary-darken-2',
    },
  },

  data() {
    return {
      localValue: false,
    };
  },

  computed: {
    hasDefaultSlot(): boolean {
      return !!this.$slots.default;
    },
    computedValue: {
      get(): boolean {
        return isPropSet(this.$options) ? this.value : this.localValue;
      },
      set(val: boolean) {
        this.$emit('input', val);
        this.localValue = val;
      },
    },
  },

  methods: {
    onInput() {
      if (this.disabled) {
        return;
      }

      this.computedValue = !this.computedValue;
    },
  },
});
</script>
