<template>
  <component
    :is="componentName"
    :[linkAttr]="linkValue"
    :target="blank ? '_blank' : null"
    class="tw-flex tw-no-underline tw-transition-colors tw-duration-100"
    :class="{
      'tw-text-primary tw-fill-primary tw-bg-primary-lighten-5': active,
      'tw-text-grey tw-fill-grey tw-bg-grey-lighten-5': disabled,
      'tw-cursor-pointer': !disabled,
      'tw-text-secondary-darken-2 tw-fill-secondary-darken-2': !active && !disabled,
      'tw-px-3 tw-py-3': !borderless,
      'hover:tw-bg-primary-lighten-4 hover:tw-fill-primary hover:tw-text-primary': !hoverless && !disabled,
    }"
    data-test="loc-dropdown-item"
    :aria-disabled="disabled"
    v-on="!disabled && $listeners"
  >
    <slot :active="active" :disabled="disabled">
      <div class="tw-flex tw-items-center tw-w-full" :class="{ 'loc-icon-disabled': disabled }">
        <div v-if="$slots['item-left'] || iconLeft" class="tw-flex tw-mr-2">
          <slot name="item-left">
            <LocIcon v-if="iconLeft" :name="iconLeft" :size="iconSize" :class="iconClasses" />
          </slot>
        </div>
        <div class="tw-flex tw-flex-grow tw-min-w-0">
          <slot name="label" :active="active" :disabled="disabled">
            {{ label }}
          </slot>
        </div>
        <div v-if="$scopedSlots['item-right'] || iconRight" class="tw-flex">
          <slot name="item-right" :active="active" :disabled="disabled">
            <LocIcon v-if="iconRight" :name="iconRight" :size="iconSize" :class="iconClasses" class="tw-ml-2" />
          </slot>
        </div>
      </div>
    </slot>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocIcon from '@/modules/@core/components/LocIcon/LocIcon.vue';

export default defineComponent({
  name: 'LocDropdownItem',

  components: {
    LocIcon,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: undefined,
    },
    href: {
      type: String,
      default: '',
    },
    blank: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    iconSize: {
      type: Number,
      default: 20,
    },
    iconClasses: {
      type: String,
      default: 'tw-p-px',
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    hoverless: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    componentName(): string {
      if (!this.disabled) {
        if (this.to) {
          return 'router-link';
        }
        if (this.href) {
          return 'a';
        }
      }
      return 'div';
    },
    linkAttr(): string {
      if (!this.disabled) {
        if (this.to) {
          return 'to';
        }
        if (this.href) {
          return 'href';
        }
      }
      return '';
    },
    linkValue(): string | null {
      return this.to || this.href || null;
    },
  },
});
</script>

<style lang="postcss" scoped>
.loc-icon-disabled >>> svg {
  filter: grayscale(1);
  /* Added to tone down clored icons */
}
</style>
